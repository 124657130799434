import React from 'react'
import Footer from '../../core/Footer'
import { Navbar } from '../../core/Navbar';
import banner from '../../images/banner.jpg';
import { Carousel } from 'react-bootstrap';
import { AnimatedPage } from '../../core/AnimatedPage';
import linkedIn from '../../images/smmLinkedIn.jpg';
import instagram from '../../images/smmInstagram.jpg';
import facebook from '../../images/smmFacebook.jpg';

export const SocialMediaMarketing = () => {
    return (
        <div>
            <Navbar />
            <Carousel variant="dark">
                <Carousel.Item>
                    <div className='overlay'>
                        <h1 className='page-title'>Social Media Marketing</h1>
                    </div>
                    <img className='header-banner' src={banner} alt="banner" />
                </Carousel.Item>
            </Carousel>
            <AnimatedPage>
                {/*<div className='service-page-content'>
                    <div className='row services-row'>
                        <div className='col-md-4 services-column'>
                            <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={facebook} />
                                <Card.Body>
                                    <h4 className='home-card-title'>Facebook Marketing</h4><br />
                                    <Card.Text>
                                        High 1 Digital provides a highly adaptable,
                                        engaging, and effective learning solution that is not only highly adaptive but also highly effective
                                        in its application. Because we are designers, engineers, thinkers, builders, and dreamers, you can
                                        count on us to assist you in making your digital products the best they can be. We aim to be
                                        strategic leaders in emerging technologies and innovators in providing user experiences. You are
                                        always welcome to communicate with us at any time and promptly. We are always eager to hear
                                        what you have to say because we believe it will help us grow. Our company has several
                                        specialities, including creating content, managing social media, running Facebook and Instagram
                                        advertisements, optimizing websites, and offering paid search services for businesses in the B2B,
                                        B2C and eCommerce sectors.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='col-md-4 services-column'>
                            <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={linkedIn} />
                                <Card.Body>
                                    <h4 className='home-card-title'>LinkedIn Marketing</h4><br />
                                    <Card.Text>
                                        Count on High 1 Digital to help you create the best digital products possible because we are
                                        designers, engineers, thinkers, builders, and dreamers. It is always our desire to be strategic
                                        leaders in emerging technologies and innovators in providing user experiences. We welcome
                                        communication at any time and in a timely fashion because we believe that it is what will help us
                                        to grow. B2B, B2C, and eCommerce businesses can benefit from our services as we create
                                        content, manage social media, run Facebook and Instagram ads, optimize websites, and perform
                                        paid searches. As a diverse group of thinkers and doers, we reimagine what's possible to help
                                        us all fulfil our passions. Our commitment to leaving the world better than we found it is reinforced
                                        by the same innovation that goes into our products. In this way, your work can make a real
                                        difference in the lives of others.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='col-md-4 services-column'>
                            <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={instagram} />
                                <Card.Body>
                                    <h4 className='home-card-title'>Instagram Marketing</h4><br />
                                    <Card.Text>
                                        Follow High 1 Digital on Instagram to make your business more worthwhile. We will let you
                                        know about the current market trends and will make your business eye catchy. Through our daily
                                        posts, you can collect all the needed information for accomplishing the business objectives. For
                                        optimization of the websites, creation of content, and Instagram advertisements, achieve the
                                        eCommerce business. As we strive to leave the world better than
                                        we found it, we can do so through the innovation we put into our products. The work that you
                                        Put into it can make a significant difference in the lives of other people in a very meaningful
                                        way. As a company, we strive to be a leader both in the field of emerging technologies and in the
                                        field of providing innovative user experiences, which is why you can count on us to help you
                                        design the perfect digital products and help you to further enhance them.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>*/}
                <div className='services-row' style={{ paddingBottom: '5%' }}>
                    <div className='row g-3' style={{ padding: '5%' }}>
                        <div className='col-md-6 align-self-center about-values'>
                            <h1 style={{ textAlign: 'center' }} className="socials">Instagram Marketing </h1><br />
                            <p>
                                Follow High 1 Digital on Instagram to make your business more worthwhile. We will let you know about the current market trends and will make your business eye catchy. Through our daily posts, you can collect all the needed information for accomplishing the business objectives. For optimization of the websites, creation of content, and Instagram advertisements, achieve the eCommerce business. As we strive to leave the world better than we found it, we can do so through the innovation we put into our products. The work that you Put into it can make a significant difference in the lives of other people in a very meaningful way. As a company, we strive to be a leader both in the field of emerging technologies and in the field of providing innovative user experiences, which is why you can count on us to help you design the perfect digital products and help you to further enhance them.
                            </p>
                        </div>
                        <div className='col-md-6 align-self-center' >
                            <img width="100%" src={instagram} alt="High1Digital" style={{ boxShadow: '2px 2px 10px 5px lightgrey' }} />
                        </div>
                    </div>
                    <div className='row g-3' style={{ padding: '5%' }}>
                        <div className='col-md-6 align-self-center'>
                            <img width="100%" src={linkedIn} alt="High1Digital" style={{ boxShadow: '2px 2px 10px 5px lightgrey' }} />
                        </div>
                        <div className='col-md-6 align-self-center about-mission'>
                            <h1 style={{ textAlign: 'center' }} className="socials">LinkedIn Marketing </h1><br />
                            <p>Count on High 1 Digital to help you create the best digital products possible because we are designers, engineers, thinkers, builders, and dreamers. It is always our desire to be strategic leaders in emerging technologies and innovators in providing user experiences. We welcome communication at any time and in a timely fashion because we believe that it is what will help us to grow. B2B, B2C, and eCommerce businesses can benefit from our services as we create content, manage social media, run Facebook and Instagram ads, optimize websites, and perform paid searches. As a diverse group of thinkers and doers, we reimagine what's possible to help us all fulfil our passions. Our commitment to leaving the world better than we found it is reinforced by the same innovation that goes into our products. In this way, your work can make a real difference in the lives of others.</p>
                        </div>
                    </div>
                    <div className='row g-3' style={{ padding: '5%' }}>
                        <div className='col-md-6 align-self-center about-vision'>
                            <h1 style={{ textAlign: 'center' }} className="socials">Facebook Marketing </h1><br />
                            <p>High 1 Digital provides a highly adaptable, engaging, and effective learning solution that is not only highly adaptive but also highly effective in its application. Because we are designers, engineers, thinkers, builders, and dreamers, you can count on us to assist you in making your digital products the best they can be. We aim to be strategic leaders in emerging technologies and innovators in providing user experiences. You are always welcome to communicate with us at any time and promptly. We are always eager to hear what you have to say because we believe it will help us grow. Our company has several specialities, including creating content, managing social media, running Facebook and Instagram advertisements, optimizing websites, and offering paid search services for businesses in the B2B, B2C and eCommerce sectors.</p>
                        </div>
                        <div className='col-md-6 align-self-center' >
                            <img width="100%" src={facebook} alt="High1Digital" style={{ boxShadow: '2px 2px 10px 5px lightgrey' }} />
                        </div>
                    </div>
                </div>
            </AnimatedPage>
            <Footer />
        </div>
    )
}
