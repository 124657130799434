import { motion } from 'framer-motion';
import React from 'react';
/*
const animations = {
    initial: { opacity: 0, x: 100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -100 }
}*/

const nonAnimations = {
    nonInitial: { opacity: 1, x: 0 },
    nonAnimate: { opacity: 1, x: 0 },
    nonExit: { opacity: 1, x: 0 }
}

export const AnimatedPage = ({ children }) => {
    return (
        <div>
            <motion.div
                variants={nonAnimations}
                initial="nonInitial"
                animate="nonAnimate"
                exit="nonExit"
                transition={{ duration: 1 }}
            >
                {children}
            </motion.div>
        </div>
    )
}
