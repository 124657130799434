import React from 'react'
import Footer from '../core/Footer'
import { Navbar } from '../core/Navbar'
import { Content } from '../sections/PageNotFound/Content'

export const PageNotfound = () => {
  return (
    <div>
        <Navbar/>
        <Content/>
        <Footer/>
    </div>
  )
}
