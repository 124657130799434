import React from 'react'
import banner from '../../images/banner.jpg';
import { Card, Carousel } from 'react-bootstrap';
import aaravConstruct3 from '../../images/aaravConstruct-image3.jpg';
import assignmentPundit3 from '../../images/assignmentPundit-image3.jpg';
import lms4 from '../../images/lms-portfolio-4.jpg';
import fourfox from '../../images/fourfox.jpg';

export const Content = () => {
    return (
        <div>
            <Carousel variant="dark">
                <Carousel.Item>
                    <div className='overlay'>
                        <h1 className='page-title'>Web Design & Development Portfolio</h1>
                    </div>
                    <img className='header-banner' src={banner} alt="banner" />
                </Carousel.Item>
            </Carousel>
            <div className='row services-row'>
                <div className='col-md-6 services-column'>
                    <Card style={{ border: '1px solid white' }}>
                        <Card.Img variant="top" src={lms4} style={{ boxShadow: '2px 2px 10px 5px lightgrey' }} />
                        <div className='hover-overlay'>
                            <Card.Body>
                                <Card.Text>
                                <h1>Workflow Management System</h1>
                                </Card.Text>
                            </Card.Body>
                        </div>
                    </Card>
                </div>
                <div className='col-md-6 services-column'>
                    <Card style={{ border: '1px solid white' }}>
                        <Card.Img variant="top" src={aaravConstruct3} style={{ boxShadow: '2px 2px 10px 5px lightgrey' }} />
                        <div className='hover-overlay'>
                            <Card.Body>
                                <Card.Text>
                                <h1>Aarav Constructions</h1>
                                </Card.Text>
                            </Card.Body>
                        </div>
                    </Card>
                </div>
                <div className='col-md-6 services-column'>
                    <Card style={{ border: '1px solid white' }}>
                        <Card.Img variant="top" src={assignmentPundit3} style={{ boxShadow: '2px 2px 10px 5px lightgrey' }} />
                        <div className='hover-overlay'>
                            <Card.Body>
                                <br />
                                <Card.Text>
                                    <h1>Assignment Pundit</h1>
                                </Card.Text>
                            </Card.Body>
                        </div>
                    </Card>
                </div>
                <div className='col-md-6 services-column'>
                    <Card style={{ border: '1px solid white' }}>
                        <Card.Img variant="top" src={fourfox} style={{ boxShadow: '2px 2px 10px 5px lightgrey' }} />
                        <div className='hover-overlay'>
                            <Card.Body>
                                <br />
                                <Card.Text>
                                    <h1>FourFox Security</h1>
                                </Card.Text>
                            </Card.Body>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    )
}
