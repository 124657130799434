import React, { useEffect, useLayoutEffect } from 'react'
import Footer from '../core/Footer'
import { Navbar } from '../core/Navbar'
import { Content } from '../sections/Education/Content'

export const Education = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  useEffect(() => {
    document.title = "Education - High1Digital Consulting Pvt. Ltd."
  }, []);

  return (
    <div>
      <Navbar />
      <Content />
      <Footer />
    </div>
  )
}
