import React from 'react'
import { Navbar } from '../core/Navbar'
import Footer from '../core/Footer';
import { useLayoutEffect } from 'react';
import { Content } from '../sections/DigitalMarketing/Content';
import { useEffect } from 'react';

export const DigitalMarketing = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    useEffect(() => {
        document.title = "Digital Marketing - High1Digital Consulting Pvt. Ltd."
    }, []);

    return (
        <div>
            <Navbar />
            <Content />
            <Footer />
        </div>
    )
}
