import React, { useState } from 'react'
import banner from '../../images/banner.jpg';
import { Carousel } from 'react-bootstrap';
import { AnimatedPage } from '../../core/AnimatedPage';
import Modal from 'react-bootstrap/Modal';
import DataService from '../../services/data.service';
import { useRef } from 'react';
import swal from '@sweetalert/with-react';

export const Content = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [position, setPosition] = useState("");
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const form_id = process.env.REACT_APP_CAREER_FORM_ID;
    const [show, setShow] = useState(false);
    let inputRef = useRef(null);

    const fileSelectHandler = (e) => {
        setFile(e.target.files[0]);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const data = new FormData();
        data.append('your_name', firstName + " " + lastName);
        data.append('your_email', email);
        data.append('MobileNumber', phone);
        data.append('Position', position);
        data.append('Resume', file);
        data.append('your_message', message);

        DataService.handleCareerForm(form_id, data)
            .then((data) => {
                if (data.data.status === "mail_sent") {
                    swal({
                        icon: "success",
                        buttons: "OK",
                        content: (
                            <div><br />
                                <h5>Successfully Applied !!!</h5>
                            </div>
                        )
                    });
                }
                else {
                    swal({
                        icon: "warning",
                        buttons: "OK",
                        content: (
                            <div><br />
                                <h5>Message Not sent. Try again after sometime.</h5>
                            </div>
                        )
                    });
                }
                setFirstName("");
                setLastName("");
                setEmail("");
                setPhone("");
                setMessage("");
                inputRef.current.value = "";
                setLoading(false);
                setShow(false);
            })
    }
    /*
    var coll = document.getElementsByClassName("collapsible");
    var i;

    for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function () {
            this.classList.toggle("active");
            var content = this.nextElementSibling;
            if (content.style.maxHeight) {
                content.style.maxHeight = null;
            } else {
                content.style.maxHeight = content.scrollHeight + "px";
            }
        });
    }*/

    const handleClick = (value) => {
        setPosition(value);
        setShow(true);
    }
    const handleClose = () => setShow(false);

    return (
        <div>
            <Carousel variant="dark">
                <Carousel.Item>
                    <div className='overlay'>
                        <h1 className='page-title'>Careers</h1>
                    </div>
                    <img className='header-banner' src={banner} alt="banner" />
                </Carousel.Item>
            </Carousel>
            <AnimatedPage>
                <div className='contact-form'>
                    <h1 className='career-page-title'>Current Job Openings :</h1>
                    <div className='row g-3 education-row'>
                        <div className='col-md-5 job-row' onClick={() => handleClick('Web Developer')}>
                            <h3 style={{ color: 'red' }}>Web Developer</h3><br />
                            <div className='requirements'>
                                <div style={{ width: '50%' }}><b>Qualification</b> : B.Tech(CSE/IT)</div>
                                <div style={{ width: '50%' }}><b>Minimum Experience</b> : 0-2 years</div>
                            </div>
                        </div>
                        <div className='col-md-5 job-row' onClick={() => handleClick('Content Writer')}>
                            <h3 style={{ color: 'red' }}>Content Writer</h3><br />
                            <div className='requirements'>
                                <div style={{ width: '50%' }}><b>Qualification</b> : Bachelors in Any Stream</div>
                                <div style={{ width: '50%' }}><b>Minimum Experience</b> : 0-2 years</div>
                            </div>
                        </div>
                    </div>
                    <div className='row g-3 education-row'>
                        <div className='col-md-5 job-row' onClick={() => handleClick('Business Development Executive')}>
                            <h3 style={{ color: 'red' }}>Business Development Executive  </h3><br />
                            <div className='requirements'>
                                <div style={{ width: '50%' }}><b>Qualification</b> : BBA, MBA</div>
                                <div style={{ width: '50%' }}><b>Minimum Experience</b> : 0-2 years</div>
                            </div>
                        </div>
                        <div className='col-md-5 job-row' onClick={() => handleClick('Digital Marketing Executive')}>
                            <h3 style={{ color: 'red' }}>Digital Marketing Executive</h3><br />
                            <div className='requirements'>
                                <div style={{ width: '50%' }}><b>Qualification</b> : Bachelors in Any Stream</div>
                                <div style={{ width: '50%' }}><b>Minimum Experience</b> : 0-2 years</div>
                            </div>
                        </div>
                    </div>
                    <div className='row g-3 education-row'>
                        <div className='col-md-5 job-row' onClick={() => handleClick('Graphic Designer')}>
                            <h3 style={{ color: 'red' }}>Graphic Designer</h3><br />
                            <div className='requirements'>
                                <div style={{ width: '50%' }}><b>Qualification</b> : Bachelors in Any Stream</div>
                                <div style={{ width: '50%' }}><b>Minimum Experience</b> : 0-2 years</div>
                            </div>
                        </div>
                        <div className='col-md-5'>
                        </div>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose} size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Applying for <span style={{ color: 'red' }}>{position}</span> Profile</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className='g-3' onSubmit={handleSubmit}>
                            <div style={{ display: 'flex' }}>
                                <div className='col-md-6' style={{ width: "49%" }}>
                                    <label className='form-label'>Name <span style={{ color: 'red' }}> *</span></label>
                                    <input type="text" placeholder='First Name' id='name' className='form-control inputbox' required
                                        value={firstName} onChange={e => setFirstName(e.target.value)} />
                                </div>
                                <div className='col-md-6' style={{ marginLeft: "2%", width: "49%" }}>
                                    <label className='form-label'><span style={{ color: 'white' }}> *</span></label>
                                    <input type="text" placeholder='Last Name' className='form-control inputbox' required
                                        value={lastName} onChange={e => setLastName(e.target.value)} />
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div className='col-md-6' style={{ width: "49%" }}><br />
                                    <label className='form-label'>Phone <span style={{ color: 'red' }}> *</span></label>
                                    <input type="text" placeholder='Phone' className='form-control inputbox' required
                                        value={phone} onChange={e => setPhone(e.target.value)} />
                                </div>
                                <div className='col-md-6' style={{ marginLeft: "2%", width: "49%" }}><br />
                                    <label className='form-label'>Email <span style={{ color: 'red' }}> *</span></label>
                                    <input type="text" placeholder='Email' className='form-control inputbox' required
                                        value={email} onChange={e => setEmail(e.target.value)} />
                                </div>
                            </div>
                            <div className='col-12'><br />
                                <label className='form-label'>Resume or CV <span style={{ color: 'red' }}> * (.pdf, .doc, .docx)</span></label>
                                <input type="file" id="file" className='form-control inputbox' required accept='.pdf,.doc,.docx'
                                    onChange={fileSelectHandler} ref={inputRef} />
                            </div>
                            <div className='col-12'><br />
                                <label className='form-label'>Description <span style={{ color: 'red' }}> *</span></label>
                                <textarea type="text" placeholder='Describe in brief, why should we hire you' className='form-control inputbox' style={{ minHeight: '120px' }} required
                                    value={message} onChange={e => setMessage(e.target.value)} />
                            </div>
                            <div className='row g-3'>
                                <div className='col-md-4'></div>
                                <div className='col-md-4'><br />
                                    <button className='btn btn-task' style={{ width: '100%' }}>
                                        {
                                            loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )
                                        }{" "}
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </AnimatedPage>
        </div>
    )
}
