import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const getBlogs = async (page) => {
    try {
        const result = await axios.get(`${API_URL}wp-json/wp/v2/posts?_embed&page=${page}`);
        return result;
    } catch (error) {
        return error;
    }
}

const getCategorisedBlogs = async (category_id) => {
    try {
        const result = await axios.get(`${API_URL}index.php/wp-json/wp/v2/posts?categories=${category_id}&_embed`);
        return result;
    } catch (error) {
        return error;
    }
}

const getBlog = async (blog_id) => {
    try {
        const result = await axios.get(`${API_URL}index.php/wp-json/wp/v2/posts/${blog_id}?_embed`);
        return result;
    } catch (error) {
        return error;
    }
}

const addComment = async (blog_id, parent, name, email, comment) => {
    try {
        const result = await axios.post(`${API_URL}wp-json/wp/v2/comments`, {
            post: blog_id,
            parent: parent,
            author_name: name,
            author_email: email,
            content: comment
        });
        return result;
    } catch (error) {
        return error;
    }
}

const handleCareerForm = async (form_id, data) => {
    try {
        const result = await axios.post(`${API_URL}wp-json/contact-form-7/v1/contact-forms/${form_id}/feedback`, data);
        return result;
    } catch (error) {
        return error;
    }
}

const handleContactForm = async (form_id, data) => {
    try {
        const result = await axios.post(`${API_URL}wp-json/contact-form-7/v1/contact-forms/${form_id}/feedback`, data);
        return result;
    } catch (error) {
        return error;
    }
}

const handleSubscribeForm = async (form_id, data) => {
    try {
        const result = await axios.post(`${API_URL}wp-json/contact-form-7/v1/contact-forms/${form_id}/feedback`, data);
        return result;
    } catch (error) {
        return error;
    }
}

const handleEducationForm = async (form_id, data) => {
    try {
        const result = await axios.post(`${API_URL}wp-json/contact-form-7/v1/contact-forms/${form_id}/feedback`, data);
        return result;
    } catch (error) {
        return error;
    }
}

const DataService = {
    getBlogs,
    getCategorisedBlogs,
    getBlog,
    addComment,
    handleCareerForm,
    handleContactForm,
    handleSubscribeForm,
    handleEducationForm
}

export default DataService;