import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Navbar } from "../../core/Navbar";
import Footer from "../../core/Footer";
import { useLocation } from "react-router-dom";
import axios from "axios";
import qrCode from "../../images/qrcode.jpg";

function Payment() {
    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const location = useLocation();
    // eslint-disable-next-line
    const amount = location.state;

    useEffect(() => {
        getKey();
        getSecret();
        // eslint-disable-next-line
    }, []);

    const getKey = async () => {
        await fetch("https://payment-server-0o1b.onrender.com/config").then(async (r) => {
            const { publishableKey } = await r.json();
            setStripePromise(loadStripe(publishableKey));
        });
    }

    const getSecret = () => {
        axios.post("https://payment-server-0o1b.onrender.com/create-payment-intent", { amount })
            .then((result) => {
                var clientSecret = result.data.clientSecret;
                setClientSecret(clientSecret);
            });
    }

    return (
        <>
            <Navbar />
            <div style={{ padding: '5% 20%' }}>
                <div style={{ textAlign: 'center' }}>
                    <h5>Scan QR to pay with any UPI app.</h5>
                    <img src={qrCode} alt="High1Digital" className="centered" style={{ width: '40vh' }} />
                </div>
                <hr />
                <br />
                {clientSecret && stripePromise && (
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <CheckoutForm amount={amount} />
                    </Elements>
                )}
            </div>
            <Footer />
        </>
    );
}

export default Payment;
