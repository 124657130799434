import React, { useEffect } from 'react'
import Footer from '../core/Footer';
import { Navbar } from '../core/Navbar';
import { useLayoutEffect } from 'react';
import { Content } from '../sections/blog/Content';


export const Blog = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  useEffect(() => {
    document.title = "Blog - High1Digital Consulting Pvt. Ltd."
  }, []);

  return (
    <div>
      <Navbar />
      <Content />
      <Footer />
    </div>
  )
}
