import React, { useEffect, useState } from 'react';
import ReactSwipeButton from 'react-swipe-button';
import Modal from 'react-bootstrap/Modal';
import DataService from '../services/data.service';
import swal from '@sweetalert/with-react';

export default function SliderButton() {
    const [show, setShow] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const form_id = process.env.REACT_APP_CONTACT_FORM_ID;
    const [layoutShift, setLayoutShift] = useState(false);

    useEffect(() => {
        if (window.innerWidth < 500) {
            setLayoutShift(true);
        }
    }, [])

    const onSuccess = () => {
        if (window.innerWidth >= 1000) {
            setShow(true);
        }
        else {
            window.location.href = "https://wa.me/6284735052";
        }
    }
    const handleClose = () => setShow(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const data = new FormData();
        data.append('your_name', firstName + " " + lastName);
        data.append('your_email', email);
        data.append('MobileNumber', phone);
        data.append('your_subject', subject);
        data.append('your_message', message);

        DataService.handleContactForm(form_id, data)
            .then((data) => {
                if (data.data.status === "mail_sent") {
                    swal({
                        icon: "success",
                        buttons: "OK",
                        content: (
                            <div><br />
                                <h5>Message Sent</h5>
                            </div>
                        )
                    });
                }
                else {
                    swal({
                        icon: "warning",
                        buttons: "OK",
                        content: (
                            <div><br />
                                <h5>Message Not sent. Try again after sometime.</h5>
                            </div>
                        )
                    });
                }
                setFirstName("")
                setLastName("")
                setEmail("")
                setPhone("")
                setMessage("")
                setSubject("")
                setLoading(false);
            })
    }

    return (
        <div>
            <ReactSwipeButton
                text={layoutShift ? <p style={{ color: 'black' }}>SWIPE</p> : <p className='swipe-text' style={{ color: 'black' }}>SWIPE TO CONTACT</p>}
                text_unlocked="DONE"
                color='#EE1919'
                onSuccess={onSuccess}
            />
            <Modal show={show} onHide={handleClose} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Please drop you details, our experts will contact you at the earliest</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='contact-form'>
                        <div className='row'>
                            <form className='col-12' onSubmit={handleSubmit}>
                                <div style={{ display: 'flex' }}>
                                    <div className='col-md-6' style={{ width: "49%" }}>
                                        <label className='form-label'>Name <span style={{ color: 'red' }}> *</span></label>
                                        <input type="text" id="name" placeholder='First Name' className='form-control inputbox' required
                                            value={firstName} onChange={e => setFirstName(e.target.value)} />
                                    </div>
                                    <div className='col-md-6' style={{ marginLeft: "2%", width: "49%" }}>
                                        <label className='form-label'><span style={{ color: 'white' }}> *</span></label>
                                        <input type="text" placeholder='Last Name' className='form-control inputbox ' required
                                            value={lastName} onChange={e => setLastName(e.target.value)} />
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div className='col-md-6' style={{ width: "49%" }}><br />
                                        <label className='form-label'>Email <span style={{ color: 'red' }}> *</span></label>
                                        <input type="text" placeholder='Email' className='form-control inputbox' required
                                            value={email} onChange={e => setEmail(e.target.value)} />
                                    </div>
                                    <div className='col-md-6' style={{ marginLeft: "2%", width: "49%" }}><br />
                                        <label className='form-label'>Phone <span style={{ color: 'red' }}> *</span></label>
                                        <input type="text" placeholder='Phone' className='form-control inputbox' required
                                            value={phone} onChange={e => setPhone(e.target.value)} />
                                    </div>
                                </div>
                                <div className='col-12'><br />
                                    <label className='form-label'>Select Service <span style={{ color: 'red' }}> *</span></label>
                                    <select className='form-control inputbox' required value={subject} onChange={e => setSubject(e.target.value)}>
                                        <option>Services</option>
                                        <option>Search Engine Optimization (SEO)</option>
                                        <option>Digital Marketing</option>
                                        <option>Content Writing</option>
                                        <option>Web Design & Development</option>
                                    </select>
                                </div>
                                <div className='col-12'><br />
                                    <label className='form-label'>Comment or Message <span style={{ color: 'red' }}> *</span></label>
                                    <textarea type="text" placeholder='Leave a message for us' className='form-control inputbox' style={{ minHeight: '120px' }} required
                                        value={message} onChange={e => setMessage(e.target.value)} />
                                </div>
                                <div className='row'>
                                    <div className='col-md-4'></div>
                                    <div className='col-md-4'><br />
                                        <button className='btn btn-task' style={{ width: '100%' }}>
                                            {
                                                loading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )
                                            }{" "}
                                            Send Message
                                        </button>
                                    </div>
                                    <div className='col-md-4'></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <br /><br />
        </div>
    )
}