import React from 'react'
import Footer from '../core/Footer'
import { Navbar } from '../core/Navbar'
import { useLayoutEffect } from 'react';
import { Content } from '../sections/ContentWriting/Content';

export const ContentWriting = () => {
    useLayoutEffect(()=>{
        window.scrollTo(0,0)
    });

    return (
        <div>
            <Navbar />
            <Content/>
            <Footer />
        </div>
    )
}
