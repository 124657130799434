import React, { useEffect, useState } from 'react'

export const ScrollToTop = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        document.title = "High1Digital Consulting Pvt. Ltd."
    }, []);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 500)
            setVisible(true)
        else if (scrolled <= 500)
            setVisible(false)
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
    window.addEventListener('scroll', toggleVisible);

    return (
        <div>
            <div className="top-to-btm">
                {visible && (<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" viewBox="0 0 16 16" className="bi bi-caret-up-square-fill icon-position icon-style" onClick={scrollToTop}>
                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm4 9h8a.5.5 0 0 0 .374-.832l-4-4.5a.5.5 0 0 0-.748 0l-4 4.5A.5.5 0 0 0 4 11z" />
                </svg>)}
            </div>
        </div>
    )
}
