import React, { useEffect, useLayoutEffect, useState } from 'react'
import banner from '../../images/banner.jpg';
import { Carousel } from 'react-bootstrap';
import { Interweave } from 'interweave';
import { Link } from 'react-router-dom';
import { AnimatedPage } from '../../core/AnimatedPage';
import DataService from '../../services/data.service';

export const Content = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [length,setLength] = useState(0);

    useLayoutEffect(()=>{
        window.scrollTo(0,0);
    })

    useEffect(() => {
        getBlogs();
        // eslint-disable-next-line
    }, [page]);

    const getBlogs = () => {
        setLoading(true);
        DataService.getBlogs(page)
            .then((data) => {
                setBlogs(data.data);
                setLoading(false);
                setLength(data.data.length);
            })
    }

    const increment = () => {
        if(length !== 0){
            setPage(page + 1);
        }
    }

    const decrement = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    return (
        <div>
            <Carousel variant="dark">
                <Carousel.Item>
                    <div className='overlay'>
                        <h1 className='page-title'>Blogs</h1>
                    </div>
                    <img className='header-banner' src={banner} alt="banner" />
                </Carousel.Item>
            </Carousel>
            {
                loading &&
                <div className="container-fluid text-center no-padding" style={{ padding: '10%' }}>
                    <div className="col-lg-6 m-auto">
                        <div class="spinner-border text-danger" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                </div>
            }
            {
                !loading && (
                    <AnimatedPage>
                        <div className='blogs'>
                            {
                                blogs?.length > 0 ?
                                    blogs.map((item) => (
                                        <div className='blog'>
                                            <Link to={`/blog/${item.slug}/${item.id}`} className='text-decoration-none'>
                                                {
                                                    item?._embedded['wp:featuredmedia'] ?
                                                        <div className='featured-image'>
                                                            <img src={item._embedded['wp:featuredmedia']['0'].source_url} alt="High1Digital" />
                                                        </div>
                                                        : ""
                                                }
                                            </Link>
                                            {
                                                item?._embedded['wp:term'].length > 0 ?
                                                    <ul className='categories'>
                                                        {
                                                            item?._embedded['wp:term'][0].map(value => (
                                                                <li className='category'>
                                                                    <Link to={`/blog/category/${value.slug}/${value.id}`} className='text-decoration-none'>
                                                                        <span>{value.name}</span>
                                                                    </Link>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul> : ""
                                            }
                                            <div className='blog-text'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <h3>
                                                            <Link to={`/blog/${item.slug}/${item.id}`} className="text-decorartion-none blog-title">
                                                                <Interweave content={item.title.rendered} />
                                                            </Link>
                                                        </h3>
                                                    </div>
                                                    <br />
                                                    <div className='col-12'>
                                                        <p className='blog-content'><Interweave content={item.content.rendered} /></p>
                                                    </div>
                                                    <br />
                                                    <div className='col-12'><br />
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <div style={{ display: 'flex', alignItems:'center' }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-person-fill" viewBox="0 0 16 16" style={{ marginTop: '-5px' }}>
                                                                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                                                </svg>
                                                                {item._embedded['author']['0'].name}
                                                            </div>
                                                            <div style={{ display: 'flex', alignItems:'center' }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='red' style={{ marginTop: '-5px' }}>
                                                                    <path d="M4.5 18q-.625 0-1.062-.448Q3 17.104 3 16.5v-11q0-.604.438-1.052Q3.875 4 4.5 4H6V2h1.5v2h5V2H14v2h1.5q.625 0 1.062.448Q17 4.896 17 5.5v11q0 .604-.438 1.052Q16.125 18 15.5 18Zm0-1.5h11V9h-11v7.5Zm0-9h11v-2h-11Zm0 0v-2 2ZM10 12q-.312 0-.531-.219-.219-.219-.219-.531 0-.312.219-.531.219-.219.531-.219.312 0 .531.219.219.219.219.531 0 .312-.219.531Q10.312 12 10 12Zm-3.25 0q-.312 0-.531-.219Q6 11.562 6 11.25q0-.312.219-.531.219-.219.531-.219.312 0 .531.219.219.219.219.531 0 .312-.219.531Q7.062 12 6.75 12Zm6.5 0q-.312 0-.531-.219-.219-.219-.219-.531 0-.312.219-.531.219-.219.531-.219.312 0 .531.219.219.219.219.531 0 .312-.219.531-.219.219-.531.219ZM10 15q-.312 0-.531-.219-.219-.219-.219-.531 0-.312.219-.531.219-.219.531-.219.312 0 .531.219.219.219.219.531 0 .312-.219.531Q10.312 15 10 15Zm-3.25 0q-.312 0-.531-.219Q6 14.562 6 14.25q0-.312.219-.531.219-.219.531-.219.312 0 .531.219.219.219.219.531 0 .312-.219.531Q7.062 15 6.75 15Zm6.5 0q-.312 0-.531-.219-.219-.219-.219-.531 0-.312.219-.531.219-.219.531-.219.312 0 .531.219.219.219.219.531 0 .312-.219.531-.219.219-.531.219Z" />
                                                                </svg>
                                                                <Interweave content={((new Date(item.date)).toString()).slice(0, 16)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <div>
                                        {
                                            !loading && (
                                                <div className="container-fluid text-center no-padding" style={{ padding: '10%' }}>
                                                    <div className="col-lg-6 m-auto">
                                                        <span>No Blog added yet</span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                            }
                            <br />
                            <div class="pagination justify-content-center">
                                {
                                    page > 1 ?
                                    <button className='btn btn-danger' onClick={decrement}>Previous</button>
                                    :
                                    <button className='btn btn-danger' disabled>Previous</button>
                                }&nbsp;&nbsp;
                                {
                                    length < 10 ?
                                    <button className='btn btn-danger' disabled>Next</button>
                                    :
                                    <button className='btn btn-danger' onClick={increment}>Next</button>
                                }
                            </div>
                        </div>
                    </AnimatedPage>
                )
            }
        </div >
    )
}