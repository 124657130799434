import React, { useState } from 'react'
import banner from '../../images/banner.jpg';
import { Carousel } from 'react-bootstrap';
import { AnimatedPage } from '../../core/AnimatedPage';
import DataService from '../../services/data.service';
import swal from '@sweetalert/with-react';

export const Content = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const form_id = process.env.REACT_APP_CONTACT_FORM_ID;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const data = new FormData();
        data.append('your_name', firstName + " " + lastName);
        data.append('your_email', email);
        data.append('MobileNumber', phone);
        data.append('your_subject', subject);
        data.append('your_message', message);

        DataService.handleContactForm(form_id, data)
            .then((data) => {
                if (data.data.status === "mail_sent") {
                    swal({
                        icon: "success",
                        buttons: "OK",
                        content: (
                            <div><br />
                                <h5>Message Sent</h5>
                            </div>
                        )
                    });
                }
                else {
                    swal({
                        icon: "success",
                        buttons: "OK",
                        content: (
                            <div><br />
                                <h5>Message Not sent. Try again after sometime.</h5>
                            </div>
                        )
                    });
                }
                setFirstName("")
                setLastName("")
                setEmail("")
                setPhone("")
                setMessage("")
                setSubject("")
                setLoading(false);
            })
    }

    return (
        <div>
            <Carousel variant="dark">
                <Carousel.Item>
                    <div className='overlay'>
                        <h1 className='page-title'>Contact Us</h1>
                    </div>
                    <img className='header-banner' src={banner} alt="banner" />
                </Carousel.Item>
            </Carousel>
            <AnimatedPage>
                <div className='contact-form'>
                    <div className='row'>
                        <form className='col-md-6' onSubmit={handleSubmit}>
                            <div style={{ display: 'flex' }}>
                                <div className='col-md-6' style={{ width: "49%" }}>
                                    <label className='form-label'>Name <span style={{ color: 'red' }}> *</span></label>
                                    <input type="text" id="name" placeholder='First Name' className='form-control inputbox' required
                                        value={firstName} onChange={e => setFirstName(e.target.value)} />
                                </div>
                                <div className='col-md-6' style={{ marginLeft: "2%", width: "49%" }}>
                                    <label className='form-label'><span style={{ color: 'white' }}> *</span></label>
                                    <input type="text" placeholder='Last Name' className='form-control inputbox ' required
                                        value={lastName} onChange={e => setLastName(e.target.value)} />
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div className='col-md-6' style={{ width: "49%" }}><br />
                                    <label className='form-label'>Email <span style={{ color: 'red' }}> *</span></label>
                                    <input type="text" placeholder='Email' className='form-control inputbox' required
                                        value={email} onChange={e => setEmail(e.target.value)} />
                                </div>
                                <div className='col-md-6' style={{ marginLeft: "2%", width: "49%" }}><br />
                                    <label className='form-label'>Phone <span style={{ color: 'red' }}> *</span></label>
                                    <input type="text" placeholder='Phone' className='form-control inputbox' required
                                        value={phone} onChange={e => setPhone(e.target.value)} />
                                </div>
                            </div>
                            <div className='col-12'><br />
                                <label className='form-label'>Select Service <span style={{ color: 'red' }}> *</span></label>
                                <select className='form-control inputbox' required value={subject} onChange={e => setSubject(e.target.value)}>
                                    <option>Services</option>
                                    <option>Search Engine Optimization (SEO)</option>
                                    <option>Digital Marketing</option>
                                    <option>Content Writing</option>
                                    <option>Web Design & Development</option>
                                </select>
                            </div>
                            <div className='col-12'><br />
                                <label className='form-label'>Comment or Message <span style={{ color: 'red' }}> *</span></label>
                                <textarea type="text" placeholder='Leave a message for us' className='form-control inputbox' style={{ minHeight: '120px' }} required
                                    value={message} onChange={e => setMessage(e.target.value)} />
                            </div>
                            <div className='col-md-4'><br />
                                <button className='btn btn-task' style={{ width: '100%' }}>
                                    {
                                        loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )
                                    }{" "}
                                    Send Message
                                </button>
                            </div>
                        </form>
                        <div className='col-md-6' style={{ padding: '3%' }}>
                            <iframe title='High1Digital' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3448.100062485377!2d74.94428141551879!3d30.20569141789141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39173394355ac44f%3A0xb3c58a34a3fb2b1e!2sHigh1Digital!5e0!3m2!1sen!2sin!4v1670490561782!5m2!1sen!2sin" width="100%" height="100%" style={{ boxShadow: '2px 2px 10px 5px lightgrey' }} allowfullscreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </AnimatedPage>
        </div>
    )
}
