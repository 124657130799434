import React from 'react'
import banner from '../../images/banner.jpg';
import { Carousel } from 'react-bootstrap';
import { AnimatedPage } from '../../core/AnimatedPage';
import vision from '../../images/vision.jpg';
import mission from '../../images/mission.jpg';
import values from '../../images/values.jpg';

export const Content = () => {
    return (
        <div>
            <Carousel variant="dark">
                <Carousel.Item>
                    <div className='overlay'>
                        <h1 className='page-title'>About Us</h1>
                    </div>
                    <img className='header-banner' src={banner} alt="banner" />
                </Carousel.Item>
            </Carousel>
            <AnimatedPage>
                <br /><br />
                <div className='col-12 text-center'>
                    <h1>Who Are We ?</h1>
                </div>
                <br /><br />
                <div className='services-row' style={{ paddingBottom: '5%' }}>
                    <div className='row g-3' style={{ padding: '5%' }}>
                        <div className='col-md-6 align-self-center about-vision'>
                            <h1 style={{ textAlign: 'center' }} className="team">Our vision </h1><br />
                            <p>Our vision is to revolutionize the way businesses look at marketing and blend new-age digital marketing with business automation to help small and medium-sized businesses to grow up the ladder and make the bigger cut. </p>
                        </div>
                        <div className='col-md-6 align-self-center' >
                            <img width="100%" src={vision} alt="High1Digital" style={{ boxShadow: '2px 2px 10px 5px lightgrey' }} />
                        </div>
                    </div>
                    <div className='row g-3' style={{ padding: '5%' }}>
                        <div className='col-md-6 align-self-center'>
                            <img width="100%" src={mission} alt="High1Digital" style={{ boxShadow: '2px 2px 10px 5px lightgrey' }} />
                        </div>
                        <div className='col-md-6 align-self-center about-mission'>
                            <h1 style={{ textAlign: 'center' }} className="team">Our mission </h1><br />
                            <p>We at High1Digital strive for value creation and delivery to our clients to help them in maximizing ROI by increasing sales and efficiency using the power of digital marketing in 2023.  </p>
                        </div>
                    </div>
                    <div className='row g-3' style={{ padding: '5%' }}>
                        <div className='col-md-6 align-self-center about-values'>
                            <h1 style={{ textAlign: 'center' }} className="team">Our values </h1><br />
                            <p>
                                <ul style={{ listStyleType: 'disc' }}>
                                    <li>Integrity</li>
                                    <li>Respect </li>
                                    <li>Responsibility</li>
                                </ul>
                                These values happen to be the pillars of our organization. We hold high respect for our clients and employees and focus on delivering value to win over their trust.
                            </p>
                        </div>
                        <div className='col-md-6 align-self-center' >
                            <img width="100%" src={values} alt="High1Digital" style={{ boxShadow: '2px 2px 10px 5px lightgrey' }} />
                        </div>
                    </div>
                </div>
            </AnimatedPage>
        </div>
    )
}
