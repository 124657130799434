import React from 'react'
import { Carousel } from 'react-bootstrap';
import { AnimatedPage } from '../../core/AnimatedPage';
import banner from '../../images/banner.jpg';
//import StripeCheckout from 'react-stripe-checkout';
//import image from '../../images/logo-title.png';
//import instaPackage from '../../images/instaPackage.jpg';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { Tab, Tabs } from '@mui/material';

export const Content = () => {
    const navigate = useNavigate();
    const [layoutShift, setLayoutShift] = useState(false);
    const [fbValue, setFbValue] = useState(0);
    const [instaValue, setInstaValue] = useState(0);
    const [googleValue, setGoogleValue] = useState(0);

    const handleClick = (price) => {
        //setAmount(price);
        //setVisible(true);
        navigate('/payment', { state: price });
    }

    useEffect(() => {
        if (window.innerWidth < 800) {
            setLayoutShift(true);
        }
    }, []);

    const facebookData = [
        {
            details: "Facebook Page Creation",
            Column2: "Yes",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Profile Optimization",
            Column2: "Yes",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Facebook Page Post Creatives",
            Column2: "Ten per month",
            Column3: "Fifteen per month",
            Column4: "Twenty per month",
            Column5: "Thirty per month",
            Column6: "Fourty per month"
        },
        {
            details: "Creative for Coverpage and upload",
            Column2: "Yes",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Facebook Stories",
            Column2: "One per week",
            Column3: "Two per week",
            Column4: "Three per week",
            Column5: "Four per week",
            Column6: "Five per week"
        },
        {
            details: "Hashtags ",
            Column2: "Yes ",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: " Engagement strategy",
            Column2: "Yes ",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Setting up Action Button ",
            Column2: "Yes ",
            Column3: "Yes ",
            Column4: "Yes ",
            Column5: "Yes ",
            Column6: "Yes "
        },
        {
            details: "Auto responses on Facebook Messenger ",
            Column2: "NA",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: " Polls and Quiz ",
            Column2: "NA ",
            Column3: "Two per month",
            Column4: "Four per month",
            Column5: "Five per month",
            Column6: "Six per month"
        },
        {
            details: "Creation of Facebook Tabs ",
            Column2: "NA ",
            Column3: "Yes ",
            Column4: "Yes ",
            Column5: "Yes ",
            Column6: "Yes "
        },
        {
            details: "Sharing Post to Different Facebook Groups",
            Column2: "NA",
            Column3: "Yes ",
            Column4: "Yes ",
            Column5: "Yes ",
            Column6: "Yes "
        },
        {
            details: "Replying To Comments",
            Column2: "NA",
            Column3: "NA",
            Column4: "Yes ",
            Column5: "Yes ",
            Column6: "Yes "
        },
        {
            details: "Linking Facebook with insta",
            Column2: "Yes",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Reel Video Creation and Posting (upto 30 sec)",
            Column2: "One in a month ",
            Column3: "Two per month",
            Column4: "Four per month",
            Column5: "Six per month",
            Column6: "Eight per month"
        },
        {
            details: "Competition analysis ",
            Column2: "Basic level",
            Column3: "Intermediate level",
            Column4: "Standard level",
            Column5: "Proficient level",
            Column6: "Superior level"
        },
        {
            details: "Facebook Automation ",
            Column2: "NA",
            Column3: "NA",
            Column4: "Yes ",
            Column5: "Yes ",
            Column6: "Yes "
        },
        {
            details: "No. of Ad copy",
            Column2: "Two ",
            Column3: "Three ",
            Column4: "Four ",
            Column5: "Six",
            Column6: "Eight"
        },
        {
            details: "No. of Ad campaigns ",
            Column2: "Two ",
            Column3: "Three ",
            Column4: "Four",
            Column5: "Six",
            Column6: "Eight"
        },
        {
            details: "Type of Ad ",
            Column2: "Single Image only",
            Column3: "Single Image and Carousel",
            Column4: "Single Image and Caroursel",
            Column5: "Single Image and Caroursel",
            Column6: "Single Image and Caroursel"
        },
        {
            details: "Ad placement ",
            Column2: "Customised ",
            Column3: "Customised ",
            Column4: "Customised ",
            Column5: "Customised ",
            Column6: "Customised "
        },
        {
            details: "Remarketing ",
            Column2: "NA",
            Column3: "Yes ",
            Column4: "Yes ",
            Column5: "Yes ",
            Column6: "Yes "
        },
        {
            details: "Detailed Audience Targeting ",
            Column2: "Yes ",
            Column3: "Yes ",
            Column4: "Yes ",
            Column5: "Yes ",
            Column6: "Yes "
        },
        {
            details: "Creation of Custom Audience ",
            Column2: "Yes ",
            Column3: "Yes ",
            Column4: "Yes ",
            Column5: "Yes ",
            Column6: "Yes "
        },
        {
            details: "Facebook Conversion Tracking",
            Column2: "NA",
            Column3: "Yes ",
            Column4: "Yes ",
            Column5: "Yes ",
            Column6: "Yes "
        },
        {
            details: "Analytics Report ",
            Column2: "One time in a month",
            Column3: "Two times a month",
            Column4: "Three times a month",
            Column5: "Four times a month",
            Column6: "Five times a month"
        },
        {
            details: "Google my business local listing",
            Column2: "NA",
            Column3: "Yes ",
            Column4: "Yes ",
            Column5: "Yes ",
            Column6: "Yes "
        },
        {
            details: "Dedicated Account Manager ",
            Column2: "Yes",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Package Cost (in INR)",
            Column2: "Rs 10000",
            Column3: "Rs 15000",
            Column4: "Rs 25000",
            Column5: "Rs 35000",
            Column6: "Rs 50000"
        },
        {
            details: "Recommended Ad Spend (in INR)",
            Column2: "Upto Rs 12000",
            Column3: "Upto Rs 30000",
            Column4: "More than Rs 30000",
            Column5: "More than Rs 30000",
            Column6: "More than Rs 30000"
        }
    ];

    const instagramData = [
        {
            details: "Professional Account Creation",
            Column2: "Yes",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Profile optimization",
            Column2: "Yes",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Insta Posts Creatives",
            Column2: "Ten per month",
            Column3: "Fifteen per month",
            Column4: "Twenty per month",
            Column5: "Thirty per month",
            Column6: "Fourty per month"
        },
        {
            details: "Insta Stories ",
            Column2: "One per week",
            Column3: "Two per week",
            Column4: "Three per week",
            Column5: "Four per week",
            Column6: "Five  per week"
        },
        {
            details: "Hashtags",
            Column2: "Yes ",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Insta engagement strategy",
            Column2: "Yes ",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Setting up Action Button ",
            Column2: "Yes ",
            Column3: "Yes ",
            Column4: "Yes ",
            Column5: "Yes ",
            Column6: "Yes "
        },
        {
            details: "Auto responses of Insta Messeges ",
            Column2: "NA",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Running Contests",
            Column2: "NA ",
            Column3: "Two per month",
            Column4: "Four per month",
            Column5: "Six per month",
            Column6: "Eight per month"
        },
        {
            details: "Ask me a question",
            Column2: "NA",
            Column3: "One per month",
            Column4: "Two per month",
            Column5: "Three per month",
            Column6: "Four per month"
        },
        {
            details: "Instagram Highlights",
            Column2: "NA",
            Column3: "Yes ",
            Column4: "Yes ",
            Column5: "Yes ",
            Column6: "Yes "
        },
        {
            details: "Grid Maintenance",
            Column2: "NA",
            Column3: "NA",
            Column4: "Yes ",
            Column5: "Yes ",
            Column6: "Yes "
        },
        {
            details: " Replying To Comments",
            Column2: "NA",
            Column3: "NA",
            Column4: "Yes ",
            Column5: "Yes ",
            Column6: "Yes "
        },
        {
            details: "Linking Insta with facebook",
            Column2: "Yes",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Reel Video Creation and Posting (30 Sec)",
            Column2: "Two per month",
            Column3: "Three per month",
            Column4: "Four per month",
            Column5: "Six per month",
            Column6: "Eight per month"
        },
        {
            details: "Competition analysis ",
            Column2: "Basic level",
            Column3: "Intermediate level",
            Column4: "Standard",
            Column5: "Proficient level",
            Column6: "Superior level"
        },
        {
            details: "Instagram Automation ",
            Column2: "NA",
            Column3: "NA",
            Column4: "Yes ",
            Column5: "Yes ",
            Column6: "Yes "
        },
        {
            details: "No. of Ad copy",
            Column2: "Two",
            Column3: "Three",
            Column4: "Four",
            Column5: "Six",
            Column6: "Eight "
        },
        {
            details: "No. of Ad campaigns ",
            Column2: "Two",
            Column3: "Three",
            Column4: "Four",
            Column5: "Six",
            Column6: "Eight"
        },
        {
            details: "Type of Ad ",
            Column2: "Single Image only",
            Column3: "Single Image and Carousel",
            Column4: "Single Image and Caroursel",
            Column5: "Single Image and Caroursel",
            Column6: "Single Image and Caroursel"
        },
        {
            details: "Influencer outreach",
            Column2: "NA",
            Column3: "NA",
            Column4: "Yes (*Extra charges)",
            Column5: "Yes (*Extra charges)",
            Column6: "Yes (*Extra charges)"
        },
        {
            details: "Detailed Audience Targeting ",
            Column2: "Yes ",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Analytics Report ",
            Column2: "One time in a month",
            Column3: "Two times a month",
            Column4: "Three times a month",
            Column5: "Four times a month",
            Column6: "Five times a month"
        },
        {
            details: "Dedicated Account Manager ",
            Column2: "Yes ",
            Column3: "Yes ",
            Column4: "Yes ",
            Column5: "Yes ",
            Column6: "Yes "
        },
        {
            details: "Package Cost (in INR)",
            Column2: "Rs 10000",
            Column3: "Rs 15000",
            Column4: "Rs 25000",
            Column5: "Rs 35000",
            Column6: "Rs 50000"
        },
        {
            details: "Recommended Ad Spend (in INR)",
            Column2: "Upto Rs 12000",
            Column3: "Upto Rs 30000",
            Column4: "More than Rs 25000",
            Column5: "More than Rs 35000",
            Column6: "More than Rs 50000"
        }
    ];

    const googleData = [
        {
            details: "Google my business listing",
            Column2: "Yes",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Search Ads Campaign",
            Column2: "Yes",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Display Ads Campaign",
            Column2: "Yes",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Gmail Ads Campaign",
            Column2: "NA",
            Column3: "Yes ",
            Column4: "Yes ",
            Column5: "Yes ",
            Column6: "Yes "
        },
        {
            details: "Shopping Ads Campaign",
            Column2: "NA",
            Column3: "NA",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "App Promotion ads ",
            Column2: "NA",
            Column3: "NA",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Video Ads ",
            Column2: "NA",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Conversion Tracking ",
            Column2: "Yes",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Remarketing ",
            Column2: "NA",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Analysis of competition level",
            Column2: "Basic",
            Column3: "Intermediate",
            Column4: "Advanced ",
            Column5: "Proficient",
            Column6: "Superior"
        },
        {
            details: "Keyword research",
            Column2: "Yes",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Optimising landing page",
            Column2: "NA",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "No. of campaigns",
            Column2: "Two",
            Column3: "Three ",
            Column4: "Four",
            Column5: "Six",
            Column6: "Eight"
        },
        {
            details: "Ad Groups",
            Column2: "Two",
            Column3: "Four",
            Column4: "Eight",
            Column5: "Twelve",
            Column6: "Fifteen"
        },
        {
            details: "Ad Copies ",
            Column2: "Five",
            Column3: "Ten",
            Column4: "Twenty",
            Column5: "Thirty",
            Column6: "Fourty"
        },
        {
            details: "Performance report ",
            Column2: "Once in a month",
            Column3: "Two per month",
            Column4: "Three per month",
            Column5: "Three per month",
            Column6: "Three per month"
        },
        {
            details: "Dedicated Account Manager",
            Column2: "Yes",
            Column3: "Yes",
            Column4: "Yes",
            Column5: "Yes",
            Column6: "Yes"
        },
        {
            details: "Google Reviews ",
            Column2: 5,
            Column3: 12,
            Column4: 20,
            Column5: 20,
            Column6: 20
        },
        {
            details: "Package Cost (in INR)",
            Column2: 10000,
            Column3: 17000,
            Column4: 25000,
            Column5: 25000,
            Column6: 25000
        },
        {
            details: "Recommended Ad Spend (in INR)",
            Column2: 10000,
            Column3: 15000,
            Column4: 25000,
            Column5: 35000,
            Column6: 50000
        }
    ];

    return (
        <div>
            <Carousel variant="dark">
                <Carousel.Item>
                    <div className='overlay'>
                        <h1 className='page-title'>Pricing</h1>
                    </div>
                    <img className='header-banner' src={banner} alt="banner" />
                </Carousel.Item>
            </Carousel>

            <AnimatedPage>
                <div>
                    <br /><br />
                    <h3 style={{ textAlign: 'center' }}>
                        <p className="moving-text">
                            Please
                            <Link to="/contact" style={{ color: 'red' }}> Contact Us </Link>
                            for more Information and Customized plans / packages
                        </p>
                    </h3>
                </div>
                <div className='text-center pricing-row'>
                    <br />
                    <h6 className='text-center packages'>FACEBOOK PACKAGES</h6>
                    <br />
                    {
                        window.innerWidth > 1100 ?
                            <table className="marketing-packages">
                                <thead>
                                    <tr>
                                        <th className="column-header">Package Inclusive</th>
                                        <th className="column-header">BASIC</th>
                                        <th className="column-header">INTERMEDIATE</th>
                                        <th className="column-header">STANDARD</th>
                                        <th className="column-header">ADVANCED</th>
                                        <th className="column-header">PREMIUM</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {facebookData.map((row, index) => (
                                        <tr key={index}>
                                            <td className="first-column">{row.details}</td>
                                            <td className="second-column">{row.Column2}</td>
                                            <td className="third-column">{row.Column3}</td>
                                            <td className="fourth-column">{row.Column4}</td>
                                            <td className="third-column">{row.Column5}</td>
                                            <td className="second-column">{row.Column6}</td>
                                        </tr>
                                    ))}
                                    <tr >
                                        <td></td>
                                        <td>
                                            <button className='btn btn-purchase' onClick={() => handleClick('10000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                        </td>
                                        <td>
                                            <button className='btn btn-purchase' onClick={() => handleClick('15000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                        </td>
                                        <td>
                                            <button className='btn btn-purchase' onClick={() => handleClick('25000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                        </td>
                                        <td>
                                            <button className='btn btn-purchase' onClick={() => handleClick('35000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                        </td>
                                        <td>
                                            <button className='btn btn-purchase' onClick={() => handleClick('50000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            :
                            window.innerWidth < 1100 && window.innerWidth > 600 ?
                                <Tabs
                                    variant="fullWidth"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                    value={fbValue}
                                    onChange={(e, newValue) => setFbValue(newValue)}
                                    centered>
                                    <Tab label="Basic" />
                                    <Tab label="Intermediate" />
                                    <Tab label="Standard" />
                                    <Tab label="Advanced" />
                                    <Tab label="Premium" />
                                </Tabs>
                                :
                                window.innerWidth < 600 ?
                                    <Tabs
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs example"
                                        value={fbValue}
                                        onChange={(e, newValue) => setFbValue(newValue)}
                                    >
                                        <Tab label="Basic" />
                                        <Tab label="Intermediate" />
                                        <Tab label="Standard" />
                                        <Tab label="Advanced" />
                                        <Tab label="Premium" />
                                    </Tabs>
                                    : null
                    }
                    {
                        window.innerWidth < 1100 ?
                            <div>
                                {
                                    (() => {
                                        switch (fbValue) {
                                            // display all users
                                            case 0:
                                                return (
                                                    <div className='text-center'>
                                                        <table className="marketing-packages">
                                                            <thead>
                                                                <tr>
                                                                    <th className="column-header">Package Inclusive</th>
                                                                    <th className="column-header">BASIC</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {facebookData.map((row, index) => (
                                                                    <tr key={index}>
                                                                        <td className="first-column">{row.details}</td>
                                                                        <td className="second-column">{row.Column2}</td>
                                                                    </tr>
                                                                ))}
                                                                <tr >
                                                                    <td></td>
                                                                    <td>
                                                                        <button className='btn btn-purchase' onClick={() => handleClick('10000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                );

                                            // display all friends
                                            case 1:
                                                return (
                                                    <div className='text-center'>
                                                        <table className="marketing-packages">
                                                            <thead>
                                                                <tr>
                                                                    <th className="column-header">Package Inclusive</th>
                                                                    <th className="column-header">INTERMEDIATE</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {facebookData.map((row, index) => (
                                                                    <tr key={index}>
                                                                        <td className="first-column">{row.details}</td>
                                                                        <td className="fourth-column">{row.Column3}</td>
                                                                    </tr>
                                                                ))}
                                                                <tr >
                                                                    <td></td>
                                                                    <td>
                                                                        <button className='btn btn-purchase' onClick={() => handleClick('15000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                );

                                            // display all requests
                                            case 2:
                                                return (
                                                    <div className='text-center'>
                                                        <table className="marketing-packages">
                                                            <thead>
                                                                <tr>
                                                                    <th className="column-header">Package Inclusive</th>
                                                                    <th className="column-header">STANDARD</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {facebookData.map((row, index) => (
                                                                    <tr key={index}>
                                                                        <td className="first-column">{row.details}</td>
                                                                        <td className="second-column">{row.Column4}</td>
                                                                    </tr>
                                                                ))}
                                                                <tr >
                                                                    <td></td>
                                                                    <td>
                                                                        <button className='btn btn-purchase' onClick={() => handleClick('25000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                );

                                            case 3:
                                                return (
                                                    <div className='text-center'>
                                                        <table className="marketing-packages">
                                                            <thead>
                                                                <tr>
                                                                    <th className="column-header">Package Inclusive</th>
                                                                    <th className="column-header">ADVANCED</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {facebookData.map((row, index) => (
                                                                    <tr key={index}>
                                                                        <td className="first-column">{row.details}</td>
                                                                        <td className="fourth-column">{row.Column5}</td>
                                                                    </tr>
                                                                ))}
                                                                <tr >
                                                                    <td></td>
                                                                    <td>
                                                                        <button className='btn btn-purchase' onClick={() => handleClick('35000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                );

                                            case 4:
                                                return (
                                                    <div className='text-center'>
                                                        <table className="marketing-packages">
                                                            <thead>
                                                                <tr>
                                                                    <th className="column-header">Package Inclusive</th>
                                                                    <th className="column-header">PREMIUM</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {facebookData.map((row, index) => (
                                                                    <tr key={index}>
                                                                        <td className="first-column">{row.details}</td>
                                                                        <td className="second-column">{row.Column6}</td>
                                                                    </tr>
                                                                ))}
                                                                <tr >
                                                                    <td></td>
                                                                    <td>
                                                                        <button className='btn btn-purchase' onClick={() => handleClick('50000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                );

                                            default:
                                                break;
                                        }
                                    })()
                                }
                            </div>
                            : null
                    }
                </div>
                <div>
                    <br /><br />
                    <h3 style={{ textAlign: 'center' }}>
                        <p className="moving-text">
                            Please
                            <Link to="/contact" style={{ color: 'red' }}> Contact Us </Link>
                            for more Information and Customized plans / packages
                        </p>
                    </h3>
                </div>
                <div className='text-center pricing-row'>
                    <br />
                    <h6 className='text-center packages'>INSTAGRAM PACKAGES</h6>
                    <br />
                    {
                        window.innerWidth > 1100 ?
                            <table className="marketing-packages">
                                <thead>
                                    <tr>
                                        <th className="column-header">Package Inclusive</th>
                                        <th className="column-header">BASIC</th>
                                        <th className="column-header">INTERMEDIATE</th>
                                        <th className="column-header">STANDARD</th>
                                        <th className="column-header">ADVANCED</th>
                                        <th className="column-header">PREMIUM</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {instagramData.map((row, index) => (
                                        <tr key={index}>
                                            <td className="first-column">{row.details}</td>
                                            <td className="second-column">{row.Column2}</td>
                                            <td className="third-column">{row.Column3}</td>
                                            <td className="fourth-column">{row.Column4}</td>
                                            <td className="third-column">{row.Column5}</td>
                                            <td className="second-column">{row.Column6}</td>
                                        </tr>
                                    ))}
                                    <tr >
                                        <td></td>
                                        <td>
                                            <button className='btn btn-purchase' onClick={() => handleClick('10000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                        </td>
                                        <td>
                                            <button className='btn btn-purchase' onClick={() => handleClick('15000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                        </td>
                                        <td>
                                            <button className='btn btn-purchase' onClick={() => handleClick('25000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                        </td>
                                        <td>
                                            <button className='btn btn-purchase' onClick={() => handleClick('35000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                        </td>
                                        <td>
                                            <button className='btn btn-purchase' onClick={() => handleClick('50000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            :
                            window.innerWidth < 1100 && window.innerWidth > 600 ?
                                <Tabs
                                    variant="fullWidth"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                    value={instaValue}
                                    onChange={(e, newValue) => setInstaValue(newValue)}
                                    centered>
                                    <Tab label="Basic" />
                                    <Tab label="Intermediate" />
                                    <Tab label="Standard" />
                                    <Tab label="Advanced" />
                                    <Tab label="Premium" />
                                </Tabs>
                                :
                                window.innerWidth < 600 ?
                                    <Tabs
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs example"
                                        value={instaValue}
                                        onChange={(e, newValue) => setInstaValue(newValue)}
                                    >
                                        <Tab label="Basic" />
                                        <Tab label="Intermediate" />
                                        <Tab label="Standard" />
                                        <Tab label="Advanced" />
                                        <Tab label="Premium" />
                                    </Tabs>
                                    : null
                    }
                    {
                        window.innerWidth < 1100 ?
                            <div>
                                {
                                    (() => {
                                        switch (instaValue) {
                                            // display all users
                                            case 0:
                                                return (
                                                    <div className='text-center'>
                                                        <table className="marketing-packages">
                                                            <thead>
                                                                <tr>
                                                                    <th className="column-header">Package Inclusive</th>
                                                                    <th className="column-header">BASIC</th>
                                                                    {/*<th className="column-header">INTERMEDIATE</th>
                                                                <th className="column-header">STANDARD</th>
                                                                <th className="column-header">ADVANCED</th>
                                                                <th className="column-header">PREMIUM</th>*/}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {instagramData.map((row, index) => (
                                                                    <tr key={index}>
                                                                        <td className="first-column">{row.details}</td>
                                                                        <td className="second-column">{row.Column2}</td>
                                                                    </tr>
                                                                ))}
                                                                <tr >
                                                                    <td></td>
                                                                    <td>
                                                                        <button className='btn btn-purchase' onClick={() => handleClick('10000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                );

                                            // display all friends
                                            case 1:
                                                return (
                                                    <div className='text-center'>
                                                        <table className="marketing-packages">
                                                            <thead>
                                                                <tr>
                                                                    <th className="column-header">Package Inclusive</th>
                                                                    <th className="column-header">INTERMEDIATE</th>
                                                                    {/*<th className="column-header">STANDARD</th>
                                                                <th className="column-header">ADVANCED</th>
                                                                <th className="column-header">PREMIUM</th>*/}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {instagramData.map((row, index) => (
                                                                    <tr key={index}>
                                                                        <td className="first-column">{row.details}</td>
                                                                        <td className="fourth-column">{row.Column3}</td>
                                                                    </tr>
                                                                ))}
                                                                <tr >
                                                                    <td></td>
                                                                    <td>
                                                                        <button className='btn btn-purchase' onClick={() => handleClick('15000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                );

                                            // display all requests
                                            case 2:
                                                return (
                                                    <div className='text-center'>
                                                        <table className="marketing-packages">
                                                            <thead>
                                                                <tr>
                                                                    <th className="column-header">Package Inclusive</th>
                                                                    <th className="column-header">STANDARD</th>
                                                                    {/*<th className="column-header">ADVANCED</th>
                                                                <th className="column-header">PREMIUM</th>*/}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {instagramData.map((row, index) => (
                                                                    <tr key={index}>
                                                                        <td className="first-column">{row.details}</td>
                                                                        <td className="second-column">{row.Column4}</td>
                                                                    </tr>
                                                                ))}
                                                                <tr >
                                                                    <td></td>
                                                                    <td>
                                                                        <button className='btn btn-purchase' onClick={() => handleClick('25000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                );

                                            case 3:
                                                return (
                                                    <div className='text-center'>
                                                        <table className="marketing-packages">
                                                            <thead>
                                                                <tr>
                                                                    <th className="column-header">Package Inclusive</th>
                                                                    <th className="column-header">ADVANCED</th>
                                                                    {/*<th className="column-header">PREMIUM</th>*/}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {instagramData.map((row, index) => (
                                                                    <tr key={index}>
                                                                        <td className="first-column">{row.details}</td>
                                                                        <td className="fourth-column">{row.Column5}</td>
                                                                    </tr>
                                                                ))}
                                                                <tr >
                                                                    <td></td>
                                                                    <td>
                                                                        <button className='btn btn-purchase' onClick={() => handleClick('35000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                );

                                            case 4:
                                                return (
                                                    <div className='text-center'>
                                                        <table className="marketing-packages">
                                                            <thead>
                                                                <tr>
                                                                    <th className="column-header">Package Inclusive</th>
                                                                    <th className="column-header">PREMIUM</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {instagramData.map((row, index) => (
                                                                    <tr key={index}>
                                                                        <td className="first-column">{row.details}</td>
                                                                        <td className="second-column">{row.Column6}</td>
                                                                    </tr>
                                                                ))}
                                                                <tr >
                                                                    <td></td>
                                                                    <td>
                                                                        <button className='btn btn-purchase' onClick={() => handleClick('50000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                );

                                            default:
                                                break;
                                        }
                                    })()
                                }
                            </div>
                            : null
                    }
                    <div>
                        <br /><br />
                        <h3 style={{ textAlign: 'center' }}>
                            <p className="moving-text">
                                Please
                                <Link to="/contact" style={{ color: 'red' }}> Contact Us </Link>
                                for more Information and Customized plans / packages
                            </p>
                        </h3>
                    </div>
                    <div className='text-center '>
                        <br />
                        <h6 className='text-center packages'>GOOGLE ADS PACKAGES</h6>
                        <br />
                        {
                            window.innerWidth > 1100 ?
                                <table className="marketing-packages">
                                    <thead>
                                        <tr>
                                            <th className="column-header">Package Inclusive</th>
                                            <th className="column-header">BASIC</th>
                                            <th className="column-header">INTERMEDIATE</th>
                                            <th className="column-header">STANDARD</th>
                                            <th className="column-header">ADVANCED</th>
                                            <th className="column-header">PREMIUM</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {googleData.map((row, index) => (
                                            <tr key={index}>
                                                <td className="first-column">{row.details}</td>
                                                <td className="second-column">{row.Column2}</td>
                                                <td className="third-column">{row.Column3}</td>
                                                <td className="fourth-column">{row.Column4}</td>
                                                <td className="third-column">{row.Column5}</td>
                                                <td className="second-column">{row.Column6}</td>
                                            </tr>
                                        ))}
                                        <tr >
                                            <td></td>
                                            <td>
                                                <button className='btn btn-purchase' onClick={() => handleClick('10000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                            </td>
                                            <td>
                                                <button className='btn btn-purchase' onClick={() => handleClick('17000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                            </td>
                                            <td>
                                                <button className='btn btn-purchase' onClick={() => handleClick('25000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                            </td>
                                            <td>
                                                <button className='btn btn-purchase' onClick={() => handleClick('25000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                            </td>
                                            <td>
                                                <button className='btn btn-purchase' onClick={() => handleClick('25000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                :
                                window.innerWidth < 1100 && window.innerWidth > 600 ?
                                    <Tabs
                                        variant="fullWidth"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs example"
                                        value={googleValue}
                                        onChange={(e, newValue) => setGoogleValue(newValue)}
                                        centered>
                                        <Tab label="Basic" />
                                        <Tab label="Intermediate" />
                                        <Tab label="Standard" />
                                        <Tab label="Advanced" />
                                        <Tab label="Premium" />
                                    </Tabs>
                                    :
                                    window.innerWidth < 600 ?
                                        <Tabs
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                            value={googleValue}
                                            onChange={(e, newValue) => setGoogleValue(newValue)}
                                        >
                                            <Tab label="Basic" />
                                            <Tab label="Intermediate" />
                                            <Tab label="Standard" />
                                            <Tab label="Advanced" />
                                            <Tab label="Premium" />
                                        </Tabs>
                                        : null

                        }
                        {
                            window.innerWidth < 1100 ?
                                <div>
                                    {
                                        (() => {
                                            switch (googleValue) {
                                                // display all users
                                                case 0:
                                                    return (
                                                        <div className='text-center'>
                                                            <table className="marketing-packages">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="column-header">Package Inclusive</th>
                                                                        <th className="column-header">BASIC</th>
                                                                        {/*<th className="column-header">INTERMEDIATE</th>
                                                                <th className="column-header">STANDARD</th>
                                                                <th className="column-header">ADVANCED</th>
                                                                <th className="column-header">PREMIUM</th>*/}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {googleData.map((row, index) => (
                                                                        <tr key={index}>
                                                                            <td className="first-column">{row.details}</td>
                                                                            <td className="second-column">{row.Column2}</td>
                                                                        </tr>
                                                                    ))}
                                                                    <tr >
                                                                        <td></td>
                                                                        <td>
                                                                            <button className='btn btn-purchase' onClick={() => handleClick('10000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    );

                                                // display all friends
                                                case 1:
                                                    return (
                                                        <div className='text-center'>
                                                            <table className="marketing-packages">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="column-header">Package Inclusive</th>
                                                                        <th className="column-header">INTERMEDIATE</th>
                                                                        {/*<th className="column-header">STANDARD</th>
                                                                <th className="column-header">ADVANCED</th>
                                                                <th className="column-header">PREMIUM</th>*/}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {instagramData.map((row, index) => (
                                                                        <tr key={index}>
                                                                            <td className="first-column">{row.details}</td>
                                                                            <td className="fourth-column">{row.Column3}</td>
                                                                        </tr>
                                                                    ))}
                                                                    <tr >
                                                                        <td></td>
                                                                        <td>
                                                                            <button className='btn btn-purchase' onClick={() => handleClick('15000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    );

                                                // display all requests
                                                case 2:
                                                    return (
                                                        <div className='text-center'>
                                                            <table className="marketing-packages">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="column-header">Package Inclusive</th>
                                                                        <th className="column-header">STANDARD</th>
                                                                        {/*<th className="column-header">ADVANCED</th>
                                                                <th className="column-header">PREMIUM</th>*/}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {googleData.map((row, index) => (
                                                                        <tr key={index}>
                                                                            <td className="first-column">{row.details}</td>
                                                                            <td className="second-column">{row.Column4}</td>
                                                                        </tr>
                                                                    ))}
                                                                    <tr >
                                                                        <td></td>
                                                                        <td>
                                                                            <button className='btn btn-purchase' onClick={() => handleClick('25000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    );

                                                case 3:
                                                    return (
                                                        <div className='text-center'>
                                                            <table className="marketing-packages">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="column-header">Package Inclusive</th>
                                                                        <th className="column-header">ADVANCED</th>
                                                                        {/*<th className="column-header">PREMIUM</th>*/}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {googleData.map((row, index) => (
                                                                        <tr key={index}>
                                                                            <td className="first-column">{row.details}</td>
                                                                            <td className="fourth-column">{row.Column5}</td>
                                                                        </tr>
                                                                    ))}
                                                                    <tr >
                                                                        <td></td>
                                                                        <td>
                                                                            <button className='btn btn-purchase' onClick={() => handleClick('25000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    );

                                                case 4:
                                                    return (
                                                        <div className='text-center'>
                                                            <table className="marketing-packages">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="column-header">Package Inclusive</th>
                                                                        <th className="column-header">PREMIUM</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {googleData.map((row, index) => (
                                                                        <tr key={index}>
                                                                            <td className="first-column">{row.details}</td>
                                                                            <td className="second-column">{row.Column6}</td>
                                                                        </tr>
                                                                    ))}
                                                                    <tr >
                                                                        <td></td>
                                                                        <td>
                                                                            <button className='btn btn-purchase' onClick={() => handleClick('25000')} style={{ color: 'white', width: layoutShift ? '95%' : '' }}>BUY NOW</button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    );

                                                default:
                                                    break;
                                            }
                                        })()
                                    }
                                </div>
                                : null
                        }
                    </div>
                </div>
            </AnimatedPage>
        </div>
    )
}
