import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import usePWAInstall from 'use-pwa-install';
import Avatar from '@mui/material/Avatar';
import logo from '../images/logo.png';

export const InstallApp = () => {
    const { isInstalled, install } = usePWAInstall();
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);

    const handleClick = () => {
        setShow(false);
        install();
    }

    return (
        <div>
            {!isInstalled && (
                <Modal show={show} onHide={handleClose} size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    style={{ marginRight: '0px' }}
                >

                    {!isInstalled && (
                        <Modal.Body>
                            <Avatar
                                variant="square"
                                alt="High1Digital"
                                src={logo}
                                sx={{ width: 175, height: 175, marginLeft: 'auto', marginRight: 'auto' }}
                            /><br />
                            <button className='btn btn-danger' onClick={handleClick} style={{ width: '100%' }}>Add to home screen</button>
                        </Modal.Body>
                    )}
                </Modal>
            )}
        </div>
    )
}
