import React from 'react'
import { Carousel } from 'react-bootstrap';
import banner from '../../images/banner.jpg';
import { AnimatedPage } from '../../core/AnimatedPage';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import DataService from '../../services/data.service';
import swal from '@sweetalert/with-react';

export const Content = () => {
    const [show, setShow] = useState(false);
    const [subject, setSubject] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const form_id = process.env.REACT_APP_EDUCATION_FORM_ID;

    const handleClick = (value) => {
        setSubject(value);
        setShow(true);
    }
    const handleClose = () => setShow(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const data = new FormData();
        data.append('your_name', firstName + " " + lastName);
        data.append('your_email', email);
        data.append('MobileNumber', phone);
        data.append('your_subject', subject);
        data.append('your_message', message);

        DataService.handleEducationForm(form_id, data)
            .then((data) => {
                if (data.data.status === "mail_sent") {
                    swal({
                        icon: "success",
                        buttons: "OK",
                        content: (
                            <div><br />
                                <h5>Application Sent</h5>
                            </div>
                        )
                    });

                    const pdfUrl = '/files/High1Digital-Course.pdf';
                    const link = document.createElement('a');
                    link.download = 'High1Digital-Course.pdf';
                    link.href = pdfUrl;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                else {
                    swal({
                        icon: "success",
                        buttons: "OK",
                        content: (
                            <div><br />
                                <h5>Message Not sent. Try again after sometime.</h5>
                            </div>
                        )
                    });
                }
                setFirstName("");
                setLastName("");
                setEmail("");
                setPhone("");
                setMessage("");
                setLoading(false);
                setShow(false);
            })
    }

    return (
        <>
            <Carousel variant="dark">
                <Carousel.Item>
                    <div className='overlay'>
                        <h1 className='page-title'>Education</h1>
                    </div>
                    <img className='header-banner' src={banner} alt="banner" />
                </Carousel.Item>
            </Carousel>

            <AnimatedPage>
                <div style={{ textAlign: 'center', padding: '0% 5% 0% 5%', marginBottom: '5%' }}><br /><br />
                    <h3>We provide exclusive trainings on different modules of digital marketing covering following modules : </h3><br />
                    <div className='row education-row'>
                        <div className='col-md-5 education-modules' onClick={() => handleClick('Facebook Marketing')}>
                            <div className='row align-items-center align-self-center w-100'>
                                <div className='col-md-7 col-sm-12'>
                                    <span>Facebook Marketing</span>
                                </div>
                                <div className='col-md-5 col-sm-12'>
                                    <button className='btn btn-download'>Download Syllabus</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5 education-modules' onClick={() => handleClick('LinkedIn Marketing')}>
                            <div className='row g-3 align-items-center align-self-center w-100'>
                                <div className='col-md-7 col-sm-12'>
                                    <span>LinkedIn Marketing</span>
                                </div>
                                <div className='col-md-5 col-sm-12'>
                                    <button className='btn btn-download'>Download Syllabus</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='row g-3 education-row'>
                        <div className='col-md-5 education-modules' onClick={() => handleClick('Web Analytics')}>
                            <div className='row g-3 align-items-center align-self-center w-100'>
                                <div className='col-md-7 col-sm-12'>
                                    <span>Web Analytics</span>
                                </div>
                                <div className='col-md-5 col-sm-12'>
                                    <button className='btn btn-download'>Download Syllabus</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5 education-modules' onClick={() => handleClick('Marketing Automation')}>
                            <div className='row g-3 align-items-center align-self-center w-100'>
                                <div className='col-md-7 col-sm-12'>
                                    <span>Marketing Automation</span>
                                </div>
                                <div className='col-md-5 col-sm-12'>
                                    <button className='btn btn-download'>Download Syllabus</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row g-3 education-row'>
                        <div className='col-md-5 education-modules' onClick={() => handleClick('Search Engine Optimization')}>
                            <div className='row g-3 align-items-center align-self-center w-100'>
                                <div className='col-md-7 col-sm-12'>
                                    <span>Search Engine Optimization</span>
                                </div>
                                <div className='col-md-5 col-sm-12'>
                                    <button className='btn btn-download'>Download Syllabus</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5 education-modules' onClick={() => handleClick('Search Engine Marketing | Google Ads')}>
                            <div className='row g-3 align-items-center align-self-center w-100'>
                                <div className='col-md-7 col-sm-12'>
                                    <span>Search Engine Marketing | Google Ads</span>
                                </div>
                                <div className='col-md-5 col-sm-12'>
                                    <button className='btn btn-download'>Download Syllabus</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row g-3 education-row'>
                        <div className='col-md-5 education-modules' onClick={() => handleClick('Email Marketing')}>
                            <div className='row g-3 align-items-center align-self-center w-100'>
                                <div className='col-md-7 col-sm-12'>
                                    <span>Email Marketing</span>
                                </div>
                                <div className='col-md-5 col-sm-12'>
                                    <button className='btn btn-download'>Download Syllabus</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5 education-modules' onClick={() => handleClick('Instagram Marketing')}>
                            <div className='row g-3 align-items-center align-self-center w-100'>
                                <div className='col-md-7 col-sm-12'>
                                    <span>Instagram Marketing</span>
                                </div>
                                <div className='col-md-5 col-sm-12'>
                                    <button className='btn btn-download'>Download Syllabus</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row g-3 education-row'>
                        <div className='col-md-5 education-modules' onClick={() => handleClick('Whatsapp Marketing')}>
                            <div className='row g-3 align-items-center align-self-center w-100'>
                                <div className='col-md-7 col-sm-12'>
                                    <span>Whatsapp Marketing</span>
                                </div>
                                <div className='col-md-5 col-sm-12'>
                                    <button className='btn btn-download'>Download Syllabus</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5 education-modules' onClick={() => handleClick('Content Writing')}>
                            <div className='row g-3 align-items-center align-self-center w-100'>
                                <div className='col-md-7 col-sm-12'>
                                    <span>Content Writing</span>
                                </div>
                                <div className='col-md-5 col-sm-12'>
                                    <button className='btn btn-download'>Download Syllabus</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row g-3 education-row'>
                        <div className='col-md-5 education-modules' onClick={() => handleClick('Website Designing')}>
                            <div className='row g-3 align-items-center align-self-center w-100'>
                                <div className='col-md-7 col-sm-12'>
                                    <span>Website Designing</span>
                                </div>
                                <div className='col-md-5 col-sm-12'>
                                    <button className='btn btn-download'>Download Syllabus</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br /><br /><br />
                <div className='container mb-5'>
                    <h1 className='text-center'>Our Placement Partners :</h1>
                    <div className='row'>
                        <div className="client-slide col-lg-3 col-md-3 col-sm-6 col-xs-12" data-bs-toggle="tooltip" data-bs-placement="top" title="Make Easy Job">
                            <img src="/partner1.png" alt="High1Digital" className='align-self-center' style={{ padding: '15%' }} />
                        </div>
                        <div className="client-slide col-lg-3 col-md-3 col-sm-6 col-xs-12" data-bs-toggle="tooltip" data-bs-placement="top" title="Make Easy Job">
                            <img src="/partner2.png" alt="High1Digital" className='align-self-center' />
                        </div>
                        <div className="client-slide col-lg-3 col-md-3 col-sm-6 col-xs-12" data-bs-toggle="tooltip" data-bs-placement="top" title="Make Easy Job">
                            <img src="/partner3.png" alt="High1Digital" className='align-self-center' />
                        </div>
                        <div className="client-slide col-lg-3 col-md-3 col-sm-6 col-xs-12" data-bs-toggle="tooltip" data-bs-placement="top" title="Make Easy Job">
                            <img src="/partner4.png" alt="High1Digital" className='align-self-center' />
                        </div>
                    </div>
                </div>
            </AnimatedPage>

            <Modal show={show} onHide={handleClose} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Applying for <span style={{ color: 'red' }}>{subject}</span> Training</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='contact-form'>
                        <div className='row'>
                            <form className='g-3' onSubmit={handleSubmit}>
                                <div style={{ display: 'flex' }}>
                                    <div className='col-md-6' style={{ width: "49%" }}>
                                        <label className='form-label'>Name <span style={{ color: 'red' }}> *</span></label>
                                        <input type="text" placeholder='First Name' id='name' className='form-control inputbox' required
                                            value={firstName} onChange={e => setFirstName(e.target.value)} />
                                    </div>
                                    <div className='col-md-6' style={{ marginLeft: "2%", width: "49%" }}>
                                        <label className='form-label'><span style={{ color: 'white' }}> *</span></label>
                                        <input type="text" placeholder='Last Name' className='form-control inputbox' required
                                            value={lastName} onChange={e => setLastName(e.target.value)} />
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div className='col-md-6' style={{ width: "49%" }}><br />
                                        <label className='form-label'>Phone <span style={{ color: 'red' }}> *</span></label>
                                        <input type="text" placeholder='Phone' className='form-control inputbox' required
                                            value={phone} onChange={e => setPhone(e.target.value)} />
                                    </div>
                                    <div className='col-md-6' style={{ marginLeft: "2%", width: "49%" }}><br />
                                        <label className='form-label'>Email <span style={{ color: 'red' }}> *</span></label>
                                        <input type="text" placeholder='Email' className='form-control inputbox' required
                                            value={email} onChange={e => setEmail(e.target.value)} />
                                    </div>
                                </div>
                                <div className='col-12'><br />
                                    <label className='form-label'>Description <span style={{ color: 'red' }}> *</span></label>
                                    <textarea type="text" placeholder='Describe your experience or subject knowledge (NA if not any)' className='form-control inputbox' style={{ minHeight: '120px' }} required
                                        value={message} onChange={e => setMessage(e.target.value)} />
                                </div>
                                <div className='row g-3'>
                                    <div className='col-md-4'></div>
                                    <div className='col-md-4'><br />
                                        <button className='btn btn-task' style={{ width: '100%' }}>
                                            {
                                                loading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )
                                            }{" "}
                                            Apply
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
