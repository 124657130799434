import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from './pages/Home';
import { Blog } from './pages/Blog';
import { Career } from './pages/Career';
import { Service } from './pages/Service';
import { About } from './pages/About';
import { Contact } from './pages/Contact';
import { ScrollToTop } from './core/ScrollToTop';
import { DesignandDev } from './pages/DesignandDev';
import { PageNotfound } from './pages/PageNotfound';
import { DigitalMarketing } from './pages/DigitalMarketing';
import { ContentWriting } from './pages/ContentWriting';
import { SpecificBlog } from './sections/blog/SpecificBlog';
import { TopNav } from './core/TopNav';
import { Pricing } from './pages/Pricing';
import { Education } from './pages/Education';
import { CategorisedBlog } from './sections/blog/CategorisedBlog';
import { SocialMediaMarketing } from './sections/service/SocialMediaMarketing';
import Payment from './sections/Pricing/Payment';
import Completion from './sections/Pricing/Completion';
import { InstallApp } from './core/InstallApp';

function App() {


  return (
    <>
      <div className='App'>
        <InstallApp />
        <TopNav />
        <ScrollToTop />
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<PageNotfound />}></Route>
            <Route path="/" element={<Home />}></Route>
            <Route path="/blog" element={<Blog />}></Route>
            <Route path="/careers" element={<Career />}></Route>
            <Route path="/services" element={<Service />}></Route>
            <Route path="/pricing" element={<Pricing />}></Route>
            <Route path="/payment" element={<Payment />} />
            <Route path="/completion" element={<Completion />} />
            <Route path="/about" element={<About />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/education" element={<Education />}></Route>
            <Route path="/webdesignanddevelopment" element={<DesignandDev />}></Route>
            <Route path="/digitalMarketing" element={<DigitalMarketing />}></Route>
            <Route path="/contentWriting" element={<ContentWriting />}></Route>
            <Route path="/blog/:blog_title/:blog_id" element={<SpecificBlog />}></Route>
            <Route path="/blog/category/:category_name/:category_id" element={<CategorisedBlog />}></Route>
            <Route path="/services/smm" element={<SocialMediaMarketing />}></Route>
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;