import Footer from "../../core/Footer";
import { Navbar } from "../../core/Navbar";
import swal from '@sweetalert/with-react';

function Completion(props) {

  swal({
    icon: "success",
    button: "OK",
    content: (
      <div><br />
        <h3>Payment Successful</h3>
      </div>
    )
  });

  return (
    <>
      <Navbar />
      <div style={{ padding: '10%' }}>
        <h1 style={{ textAlign: 'center' }}>Thank you! Your purchase is successful 🎉</h1>
      </div>
      <Footer />
    </>
  );
}

export default Completion;
