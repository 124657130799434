import React from 'react'
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { AnimatedPage } from '../../core/AnimatedPage';
import SliderButton from '../../core/SliderButton';
//import digitalMarketing from '../../images/digitalMarketing.jpg';
import homeWeb from '../../images/webdesign.jpg';
import fitfab from "../../images/fit&fab.png";
import assignmentPundit from "../../images/assignment-pundit-logo.jpg";
import fourFox from "../../images/four-fox-logo.jpg";
import lakme from "../../images/lakme.jpg";
import competitionwarrior from "../../images/competitionwarrior.jpg";
import hitashi from "../../images/hitashi.jpeg";
import furnitureHouse from "../../images/furnitureHouse.jpeg";
import ux from "../../images/ux.png";
import socialMedia from "../../images/social-media.png";
import sem from "../../images/sem.png";
import seo from "../../images/search-engine-optimization.png";
import emailMarketing from "../../images/email-marketing.png";
import dashboard from "../../images/dashboard.png";

export const Content = () => {
    /*var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 0,
        cssEase: "linear"
    };*/

    return (
        <>
            <AnimatedPage>
                <br /><br />
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <img src='/student-pic.jpg' alt='High1Digital' style={{ width: '100%' }} />
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <h2 className='mt-5 ml-5'>Why Us?</h2><br />
                            <ul style={{ listStyleType: 'disc', lineHeight: '2' }}>
                                <li>
                                    <b>High1Digital:</b> Your Digital Success Partner.
                                </li>
                                <li>
                                    <b>Dedicated to Excellence:</b> We are committed to delivering top-notch digital marketing services tailored to your unique needs.
                                </li>
                                <li>
                                    <b>Niche Techniques:</b> Utilizing specialized strategies and cutting-edge tools for maximum impact in the digital landscape.
                                </li>
                                <li>
                                    <b>Innovative Solutions:</b> Our passionate team goes beyond the conventional, providing creative and effective approaches to propel your brand forward.
                                </li>
                                <li>
                                    <b>Results-Driven:</b> At High1Digital, we measure success by the tangible results we achieve for our clients – your triumph is our ultimate goal.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <br /><br />
                <div>
                    <div className='row services-row'>
                        <div style={{ textAlign: 'center' }}><br /><br />
                            <h1>Our Services</h1>
                            <br /><br /><br />
                        </div>
                        <div className='col-md-4 service-names'>
                            <Card style={{ height: '100%', border: 'none' }}>
                                <Card.Img variant="top" src={ux} alt="High1Digital" width="100%" height="100%" style={{ width: '25%', marginRight: 'auto', marginLeft: 'auto' }} />

                                <Card.Body style={{ alignSelf: 'center' }}>
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                        <h5 style={{ cursor: 'default' }}>Website Design & Development</h5>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='col-md-4 service-names'>
                            <Card style={{ height: '100%', border: 'none' }}>
                                <Card.Img variant="top" src={socialMedia} alt="High1Digital" width="100%" height="100%" style={{ width: '25%', marginRight: 'auto', marginLeft: 'auto' }} />

                                <Card.Body style={{ alignSelf: 'center' }}>
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                        <h5 style={{ cursor: 'default' }}>Social Media Marketing</h5>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='col-md-4 service-names'>
                            <Card style={{ height: '100%', border: 'none' }}>
                                <Card.Img variant="top" src={sem} alt="High1Digital" width="100%" height="100%" style={{ width: '25%', marginRight: 'auto', marginLeft: 'auto' }} />

                                <Card.Body style={{ alignSelf: 'center' }}>
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                        <h5 style={{ cursor: 'default' }}>Search Engine Marketing</h5>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='col-md-4 service-names'>
                            <Card style={{ height: '100%', border: 'none' }}>
                                <Card.Img variant="top" src={seo} alt="High1Digital" width="100%" height="100%" style={{ width: '25%', marginRight: 'auto', marginLeft: 'auto' }} />

                                <Card.Body style={{ alignSelf: 'center' }}>
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                        <h5 style={{ cursor: 'default' }}>Search Engine Optimization</h5>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='col-md-4 service-names'>
                            <Card style={{ height: '100%', border: 'none' }}>
                                <Card.Img variant="top" src={emailMarketing} alt="High1Digital" width="100%" height="100%" style={{ width: '25%', marginRight: 'auto', marginLeft: 'auto' }} />

                                <Card.Body style={{ alignSelf: 'center' }}>
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                        <h5 style={{ cursor: 'default' }}>Email Marketing</h5>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='col-md-4 service-names'>
                            <Card style={{ height: '100%', border: 'none' }}>
                                <Card.Img variant="top" src={dashboard} alt="High1Digital" width="100%" height="100%" style={{ width: '25%', marginRight: 'auto', marginLeft: 'auto' }} />

                                <Card.Body style={{ alignSelf: 'center' }}>
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                        <h5 style={{ cursor: 'default' }}>Web Analytics</h5>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                    <br /><br />
                    <div>

                        <div className='row services-row'>
                            <div style={{ textAlign: 'center' }}><br /><br />
                                <h1>Our Clients</h1>
                                <br />
                            </div>

                            <div className='col-md-2 services-column'></div>
                            <div className='col-md-4 services-column'>
                                <Link className='text-decoration-none' to="/webdesignanddevelopment" style={{ color: 'black' }}>
                                    <Card style={{ height: '100%', border: 'none' }}>
                                        <Card.Img variant="top" src={homeWeb} alt="High1Digital" width="100%" height="100%" />
                                        <div className='hover-overlay'>
                                            <Card.Body style={{ alignSelf: 'center' }}>
                                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                                    <h1>Web Design & Development</h1>

                                                </div>
                                            </Card.Body>
                                        </div>
                                    </Card>
                                </Link>
                            </div>
                            <div className='col-md-4 services-column'>
                                <Link className='text-decoration-none' to="/digitalMarketing" style={{ color: 'black' }}>
                                    <Card style={{ height: '100%', border: 'none' }}>
                                        <Card.Img variant="top" src={"https://cdn.pixabay.com/photo/2020/12/09/03/10/digital-marketing-5816304_640.jpg"} alt="High1Digital" width="100%" height="100%" />
                                        <div className='hover-overlay'>
                                            <Card.Body style={{ alignSelf: 'center' }}>
                                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                                    <h1>Digital Marketing</h1>

                                                </div>
                                            </Card.Body>
                                        </div>
                                    </Card>
                                </Link>
                            </div>
                            <div className='col-md-2 services-column'></div>
                        </div>
                    </div>
                </div>
                <div style={{ padding: '5% 20%' }}>
                    <SliderButton />
                </div>
                {/*<div style={{ padding: '5% 30%' }}>
                    <div className='row g-3'>
                        <div className='col-md-4'>
                            <Card style={{ boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={homeWeb} style={{ borderRadius: '50%' }} />
                                <Card.Body style={{ textAlign: 'center' }}>
                                    <h5>Web Design & Development</h5>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='col-md-4'>
                            <Card style={{ boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={homeWeb} style={{ borderRadius: '50%' }} />
                                <Card.Body style={{ textAlign: 'center' }}>
                                    <h5>Web Design & Development</h5>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='col-md-4'>
                            <Card style={{ boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={homeWeb} style={{ borderRadius: '50%' }} />
                                <Card.Body style={{ textAlign: 'center' }}>
                                    <h5>Web Design & Development</h5>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>*/}
            </AnimatedPage>

            <div className="client-slider">
                <div className="slide-track2">
                    <div className="client-slide">
                        <img src={assignmentPundit} alt="High1Digital" className='align-self-center' style={{ padding: '10%' }} />
                    </div>
                    <div className="client-slide">
                        <img src={lakme} alt="High1Digital" className='align-self-center' />
                    </div>
                    <div className="client-slide">
                        <img src={competitionwarrior} alt="High1Digital" className='align-self-center' />
                    </div>
                    <div className="client-slide">
                        <img src={hitashi} alt="High1Digital" className='align-self-center' style={{ padding: '10%' }} />
                    </div>
                    <div className="client-slide">
                        <img src={fourFox} alt="High1Digital" className='align-self-center' />
                    </div>
                    <div className="client-slide">
                        <img src={fitfab} alt="High1Digital" className='align-self-center' />
                    </div>
                    <div className="client-slide">
                        <img src={furnitureHouse} alt="High1Digital" className='align-self-center' style={{ padding: '10%' }} />
                    </div>


                    <div className="client-slide">
                        <img src={assignmentPundit} alt="High1Digital" className='align-self-center' style={{ padding: '10%' }} />
                    </div>
                    <div className="client-slide">
                        <img src={lakme} alt="High1Digital" className='align-self-center' />
                    </div>
                    <div className="client-slide">
                        <img src={competitionwarrior} alt="High1Digital" className='align-self-center' />
                    </div>
                    <div className="client-slide">
                        <img src={hitashi} alt="High1Digital" className='align-self-center' style={{ padding: '10%' }} />
                    </div>
                    <div className="client-slide">
                        <img src={fourFox} alt="High1Digital" className='align-self-center' />
                    </div>
                    <div className="client-slide">
                        <img src={fitfab} alt="High1Digital" className='align-self-center' />
                    </div>
                    <div className="client-slide">
                        <img src={furnitureHouse} alt="High1Digital" className='align-self-center' style={{ padding: '10%' }} />
                    </div>


                    <div className="client-slide">
                        <img src={assignmentPundit} alt="High1Digital" className='align-self-center' style={{ padding: '10%' }} />
                    </div>
                    <div className="client-slide">
                        <img src={lakme} alt="High1Digital" className='align-self-center' />
                    </div>
                    <div className="client-slide">
                        <img src={competitionwarrior} alt="High1Digital" className='align-self-center' />
                    </div>
                    <div className="client-slide">
                        <img src={hitashi} alt="High1Digital" className='align-self-center' style={{ padding: '10%' }} />
                    </div>
                    <div className="client-slide">
                        <img src={fourFox} alt="High1Digital" className='align-self-center' />
                    </div>
                    <div className="client-slide">
                        <img src={fitfab} alt="High1Digital" className='align-self-center' />
                    </div>
                    <div className="client-slide">
                        <img src={furnitureHouse} alt="High1Digital" className='align-self-center' style={{ padding: '10%' }} />
                    </div>
                </div>
            </div>
        </>
    )
}
