import React, { useEffect } from 'react'
import Footer from '../core/Footer';
import { Navbar } from '../core/Navbar';
import { useLayoutEffect } from 'react';
import { Content } from '../sections/about/Content';

export const About = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    useEffect(() => {
        document.title = "About Us - High1Digital Consulting Pvt. Ltd."
    }, []);

    return (
        <div>
            <Navbar />
            <Content />
            <Footer />
        </div>
    )
}
