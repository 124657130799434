import React, { useEffect } from 'react'
import Footer from '../core/Footer';
import { Navbar } from '../core/Navbar';
import { useLayoutEffect } from 'react';
import { Content } from '../sections/Design&Dev/Content';

export const DesignandDev = () => {
    useLayoutEffect(()=>{
        window.scrollTo(0,0)
    });

    useEffect(() => {
        document.title = "Web Design and Development - High1Digital Consulting Pvt. Ltd."
    }, []);

    return (
        <div>
            <Navbar />
            <Content/>
            <Footer />
        </div>
    )
}
