import React from 'react'
import banner1 from '../../images/Instagramhigh1digital.png';
import banner2 from '../../images/Factshigh1digital.png';
import banner3 from '../../images/3.png';
import banner4 from '../../images/5 Most Effective Digital.png';
import banner5 from '../../images/Wishing you all a Happy and Prosperous New Year.png';
import banner6 from '../../images/Poster 1.png';
import banner7 from '../../images/mathbanner.png';
import banner8 from '../../images/furniturehouse banner 3.png';
import banner9 from '../../images/furniturehouse banner.png';
import banner10 from '../../images/5apps.png';
import banner11 from '../../images/WhatsApp Image.jpeg';
import banner12 from '../../images/2.jpeg';
import banner from '../../images/banner.jpg';
import { Card, Carousel } from 'react-bootstrap'
import { AnimatedPage } from '../../core/AnimatedPage';

export const Content = () => {
    return (
        <div>
            <Carousel variant="dark">
                <Carousel.Item>
                    <div className='overlay'>
                        <h1 className='page-title'>Digital Marketing Portfolio</h1>
                    </div>
                    <img className='header-banner' src={banner} alt="banner" />
                </Carousel.Item>
            </Carousel>
            <AnimatedPage>
                <div className='row services-row'>
                    <div className='col-12 team'>
                        <h1 style={{ textAlign: 'center' }}>Banners</h1><br />
                    </div>
                    <div className='row services-row'>
                        <div className='col-md-6 services-column'>
                            <Card style={{ boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={banner7} />
                            </Card>
                        </div>
                        <div className='col-md-6 services-column'>
                            <Card style={{ boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={banner4} />
                            </Card>
                        </div>
                    </div>
                    <div className='row services-row'>
                        <div className='col-md-6 services-column'>
                            <Card style={{ boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={banner8} />
                            </Card>
                        </div>
                        <div className='col-md-6 services-column'>
                            <Card style={{ boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={banner9} />
                            </Card>
                        </div>
                    </div>
                    <div className='row services-row'>
                        <div className='col-md-6 services-column'>
                            <Card style={{ boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={banner5} />
                            </Card>
                        </div>
                        <div className='col-md-6 services-column'>
                            <Card style={{ boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={banner11} />
                            </Card>
                        </div>
                    </div>
                    <div className='row services-row'>
                        <div className='col-md-6 services-column'>
                            <Card style={{ boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={banner12} />
                            </Card>
                        </div>
                        <div className='col-md-6 services-column'>
                            <Card style={{ boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={banner10} />
                            </Card>
                        </div>
                    </div>
                    <div className='row services-row'>
                        <div className='col-md-6 services-column'>
                            <Card style={{ boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={banner1} />
                            </Card>
                        </div>
                        <div className='col-md-6 services-column'>
                            <Card style={{ boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={banner2} />
                            </Card>
                        </div>
                    </div>
                    <div className='row services-row'>
                        <div className='col-md-6 services-column'>
                            <Card style={{ boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={banner6} />
                            </Card>
                        </div>
                        <div className='col-md-6 services-column'>
                            <Card style={{ boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={banner3} />
                            </Card>
                        </div>
                    </div>
                </div>
            </AnimatedPage>
        </div>
    )
}
