import React from 'react'
import banner from '../../images/banner.jpg';
import { Link } from 'react-router-dom';
import { Card, Carousel } from 'react-bootstrap'
import { AnimatedPage } from '../../core/AnimatedPage';

export const Content = () => {
    return (
        <div>
            <Carousel variant="dark">
                <Carousel.Item>
                    <div className='overlay'>
                        <h1 className='page-title'>Content Writing</h1>
                    </div>
                    <img className='header-banner' src={banner} alt="banner" />
                </Carousel.Item>
            </Carousel>
            <AnimatedPage>
                <div className='row services-row'>
                    <div className='col-md-4 services-column'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src="https://high1digital.com/wp-content/uploads/2022/11/WebsiteDesignandDevelopment-high1digital-300x169.jpg" />
                            <Card.Body>
                                <Link className='text-decoration-none' to="/">
                                    <h5 className='home-card-title'>SEO Content Writer</h5>
                                </Link>
                                <br />
                                <Card.Text>
                                    Designing is way of life. High 1 Digital believes in designing a unique end user experience and help our customers stand apart from its competitors. our company offers to help you develop the applications from scratch and create a unique brand identity. The aesthetic of brand and help to reach the target audience.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col-md-4 services-column'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src="https://high1digital.com/wp-content/uploads/2022/11/Digital-Marketingseo-smm-ppc-high-1-digital-300x169.png" />
                            <Card.Body>
                                <Link className='text-decoration-none' to="/">
                                    <h5 className='home-card-title'>Blog Content Writer</h5>
                                </Link>
                                <br />
                                <Card.Text>
                                    High 1 digital offers a single-point toolkit for all your digital marketing needs. Increase your online presence with our blend of data driven digital marketing services. Social media marketing (SMM) , Google ads, Search engine Optimization (SEO), Email marketing, Web analytics.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col-md-4 services-column'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src="https://high1digital.com/wp-content/uploads/2022/11/content-writing-banner-high-1-digital-300x169.png" />
                            <Card.Body>
                                <Link className='text-decoration-none' to="/">
                                    <h5 className='home-card-title'>Long-Form Content Writer</h5>
                                </Link>
                                <br />
                                <Card.Text>
                                    High 1 digital strives to enhance your business visibility by offering exclusive content writing services which includes SEO writing . Well-documented content not only enhances awareness of the brand but also likely to generate more business for our customers.our company unique professional copywriting services.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className='row services-row'>
                    <div className='col-md-4 services-column'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src="https://high1digital.com/wp-content/uploads/2022/11/WebsiteDesignandDevelopment-high1digital-300x169.jpg" />
                            <Card.Body>
                                <Link className='text-decoration-none' to="/">
                                    <h5 className='home-card-title'>Social Media Content Writer</h5>
                                </Link>
                                <br />
                                <Card.Text>
                                    Designing is way of life. High 1 Digital believes in designing a unique end user experience and help our customers stand apart from its competitors. our company offers to help you develop the applications from scratch and create a unique brand identity. The aesthetic of brand and help to reach the target audience.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col-md-4 services-column'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src="https://high1digital.com/wp-content/uploads/2022/11/Digital-Marketingseo-smm-ppc-high-1-digital-300x169.png" />
                            <Card.Body>
                                <Link className='text-decoration-none' to="/">
                                    <h5 className='home-card-title'>Report Writer</h5>
                                </Link>
                                <br />
                                <Card.Text>
                                    High 1 digital offers a single-point toolkit for all your digital marketing needs. Increase your online presence with our blend of data driven digital marketing services. Social media marketing (SMM) , Google ads, Search engine Optimization (SEO), Email marketing, Web analytics.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col-md-4 services-column'>
                        <Card style={{ height: '100%', boxShadow: '2px 2px 10px 5px lightgrey' }}>
                            <Card.Img variant="top" src="https://high1digital.com/wp-content/uploads/2022/11/content-writing-banner-high-1-digital-300x169.png" />
                            <Card.Body>
                                <Link className='text-decoration-none' to="/">
                                    <h5 className='home-card-title'>CopyWriter</h5>
                                </Link>
                                <br />
                                <Card.Text>
                                    High 1 digital strives to enhance your business visibility by offering exclusive content writing services which includes SEO writing . Well-documented content not only enhances awareness of the brand but also likely to generate more business for our customers.our company unique professional copywriting services.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </AnimatedPage>
        </div>
    )
}
