import React from 'react'
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { AnimatedPage } from '../../core/AnimatedPage';
import banner from '../../images/banner.jpg';

export const Content = () => {

    return (
        <div>
            <Carousel variant="dark">
                <Carousel.Item>
                    <div className='overlay'>
                        <h1 className='page-title'>Error 404</h1>
                    </div>
                    <img className='header-banner' src={banner} alt="banner" />
                </Carousel.Item>
            </Carousel>
            <AnimatedPage>
                <div style={{ textAlign: 'center', padding: '5%' }}>
                    <h1 className='error-text'>404</h1>
                    <h6 style={{ fontSize: '2rem' }}>Sorry we cannot find that page! Go back to home</h6><br /><br />
                    <button className='btn btn-task'>
                        <Link to="/" className='text-decoration-none' style={{ color: 'white' }}>Home Page</Link>
                    </button>
                </div>
            </AnimatedPage>
        </div>
    )
}
