import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router-dom';
import image1 from '../images/header-bg-1.jpeg';
import image2 from '../images/header-bg-2.jpg';
import image3 from '../images/header-bg-3.jpg';

export const Banner = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/contact');
    }

    return (
        <>
            <div>
                <Carousel variant="light">
                    <Carousel.Item>
                        <div className='banner-overlay'>
                            <div className='slider-label'>
                                <p className='banner-subheading'>We Provide Best</p>
                                <h1 className='banner-heading'>Digital Marketing</h1>
                                <h1 className='banner-heading' style={{ color: 'red' }}>Training</h1>
                                <p className='banner-description'>We create and build flexible &amp; creative design in your budget. Helping you get increase in sales.</p><br />
                                <button className='btn btn-banner' onClick={handleClick}>Free Consultation</button>
                            </div>
                        </div>
                        <img className='banner-image'
                            src={image1}
                            alt="High1Digital"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='banner-overlay'>
                            <div className='slider-label'>
                                <p className='banner-subheading'>We Are Best</p>
                                <h1 className='banner-heading'>Digital Marketing</h1>
                                <h1 className='banner-heading' style={{ color: 'red' }}>Agency</h1>
                                <p className='banner-description'>Digital Marketing for Small Businesses and Rising Entrepreneurs. Helping to scale your business</p><br />
                                <button className='btn btn-banner' onClick={handleClick}>Free Consultation</button>
                            </div>
                        </div>
                        <img className='banner-image'
                            src={image3}
                            alt="High1Digital"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='banner-overlay'>
                            <div className='slider-label'>
                                <p className='banner-subheading'>We provide</p>
                                <h1 className='banner-heading'>Better Insights for </h1>
                                <h1 className='banner-heading' style={{ color: 'red' }}>Business</h1>
                                <p className='banner-description'>We create and build flexible &amp; creative design in your budget. Helping you get increase in sales.</p><br />
                                <button className='btn btn-banner' onClick={handleClick}>Free Consultation</button>
                            </div>
                        </div>
                        <img className='banner-image'
                            src={image2}
                            alt="High1Digital"
                        />
                    </Carousel.Item>

                </Carousel >
            </div>
        </>
    );
}