import React from 'react'
import { Banner } from '../core/Banner'
import Footer from '../core/Footer'
import { Navbar } from '../core/Navbar';
import { useLayoutEffect } from 'react';
import { Content } from '../sections/home/Content';
import { useEffect } from 'react';

export const Home = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  useEffect(() => {
    document.title = "High1Digital Consulting Pvt. Ltd."
  }, []);

  return (
    <div>
      <Navbar />
      <Banner />
      <Content />
      <Footer />
    </div >
  )
}
