import React from 'react'
import banner from '../../images/banner.jpg';
import Card from 'react-bootstrap/Card';
import { Carousel } from 'react-bootstrap';
import { AnimatedPage } from '../../core/AnimatedPage';
import { Link } from 'react-router-dom';
import serviceWeb from '../../images/serviceWeb.jpg';
import smm from '../../images/smm.jpg';
import seo from '../../images/seo.jpg';
import emailMarketing from '../../images/emailMarketing.jpg';
//import arrowLink from '../../images/box-arrow-up-right.svg';
import webAnalytics from '../../images/web-analytics.jpg';
import sem from '../../images/sem.jpg';

export const Content = () => {
    return (
        <div>
            <Carousel variant="dark">
                <Carousel.Item>
                    <div className='overlay'>
                        <h1 className='page-title'>Services</h1>
                    </div>
                    <img className='header-banner' src={banner} alt="banner" />
                </Carousel.Item>
            </Carousel>
            <AnimatedPage>
                <div className='service-page-content'>
                    <div className='row services-row'>
                        <div className='col-md-4 services-column'>
                            <Card style={{ boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={serviceWeb} />
                                <Card.Body>
                                    <h4 className='home-card-title'>Website Design & Development</h4><br />
                                    <Card.Text>
                                        This feature describes
                                        creating and maintaining a website. Our website developers help in
                                        giving particular perception to life with the help of Word Press without
                                        using any kind of coding and any design. We ensure that we will create a
                                        customized and mobile-optimized website in a single hour with our help.
                                        Our company offers various kinds of web development services and
                                        providing workers having delicate and user-friendly utilizing the latest
                                        technologies.

                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='col-md-4 services-column'>
                            <Card style={{ boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={smm} />
                                <Card.Body>
                                    <Link className='text-decoration-none' to="/services/smm">
                                        <h4 className='home-card-title' style={{ cursor: 'pointer' }}>Social Media Marketing </h4>
                                        <br />
                                    </Link>
                                    <Card.Text>
                                        We provide social media marketing services
                                        that help to stimulate a product or any service.
                                        Social media marketing is a complex problem that is hard to beat.
                                        Surprisingly, our social media marketing services help you to manage
                                        your aspect of social media. It also allows you to connect with your
                                        audience to build your product and increase sales.

                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='col-md-4 services-column'>
                            <Card style={{ boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={sem} />
                                <Card.Body>
                                    <h4 className='home-card-title'>Search Engine Marketing</h4><br />
                                    <Card.Text>
                                        Search engine marketing is a valuable
                                        technique in which we provide a mode of internet marketing which
                                        helps to surge the presence in search engines, including promotion. There are a plethora of search engines available. For
                                        instance, Google, yahoo, Bing, Yandex and many more. Although,
                                        the Google search engine interacts with multiple searches uniformly.

                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                    <div className='row services-row'>
                        <div className='col-md-4 services-column'>
                            <Card style={{ boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={seo} />
                                <Card.Body>
                                    <h4 className='home-card-title'>Search Engine Optimization</h4><br />
                                    <Card.Text>
                                        We go through with the solidity of
                                        Content and improve the quality of the site to expand its clarity while the
                                        Products or services are explored by individuals. Our original search on
                                        the website. It also helps to make more leads and business sales. The primary
                                        aim of SEO is to take the first rank on the Google page during a user search as appearing in search results.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='col-md-4 services-column'>
                            <Card style={{ boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={emailMarketing} height="60%" />
                                <Card.Body>
                                    <h4 className='home-card-title'>Email Marketing</h4><br />
                                    <Card.Text>
                                        We provide another service, that is email
                                        marketing. This enables to the creation and sends a commercial message that
                                        is very useful for building a solid relationship between customers and clients
                                        by using attractive mail. The various features, such as live chats,
                                        Facebook adds collaboration is added into our premium plans.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='col-md-4 services-column'>
                            <Card style={{ boxShadow: '2px 2px 10px 5px lightgrey' }}>
                                <Card.Img variant="top" src={webAnalytics} style={{ height: '35vh' }} />
                                <Card.Body>
                                    <h4 className='home-card-title'>Web Analytics</h4><br />
                                    <Card.Text>
                                        We have a feature which goes through with collecting,
                                        measuring and analysing the website data for the aim of recognizing and
                                        enhancing web usage. In other words, web analytics helps
                                        to experience the trends in real-time data. Our digital services provide a
                                        detailed analysis of how to access the performance of a website. Not
                                        only this, we also acknowledge that to judge the success and power of
                                        business activities and marketing, web analytics plays a vital role.

                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </AnimatedPage>
        </div>
    )
}
